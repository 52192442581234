import { useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import Badge, { BadgeColor } from 'atoms/Badge';
import TabContainer from 'components/TabContainer';
import TransactionHistoryGeneralStatus from 'common/model/TransactionHistoryGeneralStatus';
import ValidDocumentStatus from 'common/model/ValidDocumentStatus';
import { listMembers } from 'common/api/members';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import PercentageModal from '../History/PercentageModal';
import DocUploadBox from './DocUploadBox';
import PostCompletionTabToolbar from './PostCompletionTabToolbar';
import '../History/History.scss';
import '../History/Tabs/Doc/Doc.scss';
import './PostCompletion.scss';

interface UploadDocumentType {
  name: string;
  required: boolean;
}

const PostCompletion = (): JSX.Element => {
  // Status of Accordians inside the Tab
  const dispatch = useAppDispatch();

  const {
    activeTransaction: { historyGeneral, historyDocuments, regulatories },
  } = useAppSelector((state: RootState) => state.transactionDetail);

  useEffect(() => {
    // @ts-ignore
    dispatch(listMembers({ first: 100 }));
  }, []);

  //define the list of document types to upload
  const OtherDocuments: UploadDocumentType = {
    name: 'Other documents',
    required: false,
  };

  const uploadDocumentTypes: UploadDocumentType[] = regulatories
    .map((name: string) => {
      const result: UploadDocumentType = {
        name,
        required: true,
      };
      return result;
    })
    .concat(OtherDocuments);

  /* Check that all documents have been signed*/
  // MIGHT NOT BE NEEDED
  const checkDocumentsAreAllSigned = (): boolean => {
    const numberOfDocs = historyDocuments.length;
    if (numberOfDocs === 0) {
      return false;
    }
    let totalSigned = 0;
    for (let i = 0; i < numberOfDocs; i++) {
      const { history } = historyDocuments[i];
      for (let j = 0; j < history.length; j++) {
        // @ts-ignore
        const { status } = history[j];
        if (status === ValidDocumentStatus.Signed) {
          totalSigned += 1;
        }
      }
    }

    return numberOfDocs === totalSigned;
  };

  const allSigned: boolean = checkDocumentsAreAllSigned();
  const checkCurrentStatus: TransactionHistoryGeneralStatus = historyGeneral.at(-1)!.status;
  const setup: boolean = checkCurrentStatus === TransactionHistoryGeneralStatus.Setup;
  const closed: boolean = checkCurrentStatus === TransactionHistoryGeneralStatus.Closed;

  const [mode, setMode] = useState<MODE_OPTIONS>(MODE_OPTIONS.READ);

  if (historyGeneral.length === 1) {
    return <></>;
  }

  return (
    <TabContainer
      className="pre-completion-tab post-completion"
      tabTitle="Post Completion"
      tabToolbar={
        <PostCompletionTabToolbar
          allSigned={allSigned}
          checkCurrentStatus={checkCurrentStatus}
          mode={mode}
          setMode={setMode}
        />
      }
    >
      <>
        {!allSigned && (
          <Badge
            className="post-completion-warning-badge"
            badgeColor={BadgeColor.DARK_ORANGE}
          >
            Please approve and upload signed documents on the Pre-Completion tab before performing post-completion
            actions
          </Badge>
        )}
        {closed && (
          <Badge
            className={!allSigned ? 'finalized-badge' : ''}
            badgeColor={BadgeColor.GREEN}
          >
            FINALIZED
          </Badge>
        )}

        {!setup && (
          <div className="doc-boxes">
            <div className="docs-box">
              <PercentageModal modalText="Finalising transaction..." />
              <Grid
                columns={2}
                className="list-of-boxes"
              >
                <Grid.Row>
                  {uploadDocumentTypes.map((type: UploadDocumentType) => (
                    <Grid.Column
                      key={`upload-${type.name}`}
                      className="inner-column"
                    >
                      <DocUploadBox
                        mode={mode}
                        name={type.name}
                        required={type.required}
                      />
                    </Grid.Column>
                  ))}
                </Grid.Row>
              </Grid>
            </div>
          </div>
        )}
      </>
    </TabContainer>
  );
};

export default PostCompletion;
