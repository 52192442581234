import { ProvisionListingClone } from 'common/_classes';
import { useEffect, useState } from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { Editor } from 'tinymce';
import { useAppDispatch, useAppSelector } from 'hooks';
import { sortBy } from 'lodash';
import SelectField from 'atoms/FormField/Select';
import ModalTemplate from 'components/ModalTemplate';
import { getClause, getClauseNumber } from 'components/PreviewTab/Discussion';
import { setCrossRefDetails, setCrossRefModal } from 'store/provisions/provisionDetailSlice';
import { DropdownProps } from 'common/api/miscellaneous';
import { RefData, createClauseReferenceNode } from 'common/api/nodes/createClauseReferenceNode';
import { ProvisionContentOnlyDocTypeId, listProvisions } from 'common/api/provisions';
import { DROPDOWN_OPTION } from 'utils/UI';
import './CrossReferenceModal.scss';

const provisionsDropdown = (provisions: ProvisionListingClone[]): DropdownProps[] => {
  const provisionsDropdown = provisions.map((item: ProvisionListingClone) => ({
    key: item?.id,
    text: item?.name,
    value: item?.id,
  }));

  return sortBy(provisionsDropdown, 'text') as DropdownProps[];
};

const CrossReferenceModal = ({ editor }: { editor: Editor | null }) => {
  const { provisionsList } = useAppSelector((state: RootState) => state.provisionsListing);
  const { crossRefModal, crossRefDetails, activeProvision } = useAppSelector(
    (state: RootState) => state.provisionDetail,
  );

  const { documentTypesOptions } = useAppSelector((state: RootState) => state.miscellaneous);

  const provisions = provisionsDropdown(provisionsList);

  const dispatch = useAppDispatch();

  const [provision, setProvision] = useState(crossRefDetails?.provisionId);
  const [documentType, setDocumentType] = useState(crossRefDetails?.docId);
  const [selectedClause, setClauseNumber] = useState(crossRefDetails?.clauseId);

  const checkIfUpdate = crossRefDetails !== null ? true : false;

  useEffect(() => {
    setProvision(crossRefDetails?.provisionId);
    setDocumentType(crossRefDetails?.docId);
    setClauseNumber(crossRefDetails?.clauseId);
  }, [crossRefDetails]);

  let selectedProvision = provisionsList.find((obj: ProvisionListingClone) => obj.id === provision);

  if (selectedProvision && selectedProvision.id === activeProvision.id) {
    selectedProvision = activeProvision as any;
  }

  const selectedProvisionDocs = selectedProvision?.contents.map(
    (content: ProvisionContentOnlyDocTypeId) => content.documentTypeId,
  );

  const selectedDocOptions = documentTypesOptions.filter((obj: DROPDOWN_OPTION) =>
    selectedProvisionDocs?.includes(obj.value as string),
  );

  const getClauseNumbers = () => {
    const selectedContent = selectedProvision?.contents.find(
      (obj: ProvisionContentOnlyDocTypeId) => obj.documentTypeId === documentType,
      // @ts-ignore
    )?.content;

    const parser = new DOMParser();
    const doc = parser.parseFromString(selectedContent, 'text/html');

    // Select all elements with data-node-type="clause"
    const clauses = doc.querySelectorAll('[data-node-type="clause"]');

    // Extract and print all data-node-id values
    const nodeIds = Array.from(clauses).map(el => el.getAttribute('data-node-id'));

    let clauseNumbers = [];
    for (let i = 0; i < nodeIds.length; i++) {
      const clause = getClause(nodeIds[i] as string, doc);
      let number = getClauseNumber(clause, true);

      // Remove spaces
      number = number.replace(/\s+/g, '');
      // Remove dot from last item
      number = number.replace(/\.$/, '');
      // Remove double dots
      number = number.replace(/\.\.+/g, '.');

      clauseNumbers.push({
        key: nodeIds[i],
        text: number,
        value: nodeIds[i],
      });
    }

    return clauseNumbers;
  };

  const clauseNumbers = getClauseNumbers();

  const onChange = (key: string, value: string) => {
    if (key === 'provision') {
      setProvision(value);
    } else if (key === 'document') {
      setDocumentType(value);
    } else if (key === 'selectedClause') {
      setClauseNumber(value);
    }
  };

  useEffect(() => {
    dispatch(
      listProvisions({
        first: 500,
        withContent: true,
      }),
    );
  }, []);

  const crossRefDisabled = () => {
    return provision === '' || documentType === '' || selectedClause === '';
  };

  interface DropdownOption {
    key: string | null;
    text: string;
    value: string | null;
  }

  const addCrossReference = () => {
    const provisionName = provisions.find((obj: DropdownProps) => obj.value === provision)?.text;
    const documentName = documentTypesOptions.find((obj: DROPDOWN_OPTION) => obj.value === documentType)?.text;
    const clauseNumber = clauseNumbers.find((obj: DropdownOption) => obj.value === selectedClause)?.text;

    const innerContent = `${provisionName}.${documentName}.clause ${clauseNumber}`;
    const refData = {
      provisionId: provision,
      docId: documentType,
      clauseId: selectedClause,
    };

    dispatch(
      createClauseReferenceNode({ editor: editor as Editor, innerContent, refData: refData as RefData, checkIfUpdate }),
    );

    dispatch(setCrossRefModal(false));
    dispatch(setCrossRefDetails(null));
  };

  const onClose = () => {
    dispatch(setCrossRefDetails(null));
  };

  const btnType = checkIfUpdate ? 'UPDATE' : 'ADD';

  return (
    <ModalTemplate
      title="Cross Reference Modal"
      showHeader={true}
      isOpen={crossRefModal}
      onToggleModalStatus={isOpen => dispatch(setCrossRefModal(isOpen))}
      className="cross-reference-modal"
      onModalClose={onClose}
    >
      <Grid>
        <Grid.Row className="p-none btn-row">
          <Grid.Column
            width={16}
            className="p-none"
          >
            <SelectField
              label="Select provision"
              value={provision}
              placeholder="Select provision..."
              options={provisions}
              required={true}
              search={true}
              onChange={onChange}
              fieldKey="provision"
            />
            <SelectField
              label="Select document/articulation"
              value={documentType}
              placeholder="Select document/articulation..."
              options={selectedDocOptions}
              required={true}
              onChange={onChange}
              fieldKey="document"
            />
            <SelectField
              label="Select clause number"
              value={selectedClause}
              placeholder="Select clause number..."
              options={clauseNumbers}
              required={true}
              search={true}
              onChange={onChange}
              fieldKey="selectedClause"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Button
            className="btn grey-bg cross-ref"
            disabled={crossRefDisabled()}
            onClick={() => addCrossReference()}
          >
            {btnType}
          </Button>
        </Grid.Row>
      </Grid>
    </ModalTemplate>
  );
};

export default CrossReferenceModal;
