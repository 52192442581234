import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import FullScreen from 'layouts/FullScreen';
import DropdownMenu, { DropdownItem } from 'atoms/DropdownMenu';
import CreateDiscussionModal from 'components/ContextToolbar/DiscussionToolbar/CreateDiscussionModal';
import TabContainer from 'components/TabContainer';
import { updateDocTypeId } from 'store/miscellaneous/miscellaneousSlice';
import ExecuteContext from 'common/model/ExecuteContext';
import { getPDFDocument, getPdfHtml } from 'common/api/miscellaneous';
import { getPolicy } from 'common/api/policies';
import { PolicyProps } from 'common/api/policies';
import { setCrossRefInPolicy } from 'common/api/policies/setCrossRefInPolicy';
import { getLandlordTransaction } from 'common/api/transactions';
import { TransactionProps } from 'common/api/transactions';
import { setCrossRefInTransaction } from 'common/api/transactions/setCrossRefInTransaction';
import { checkIfTenantURL } from 'utils/utils-admin';
import { Icons } from 'utils/utils-icons';
import PreviewEditorMain from './PreviewEditorMain';
import PreviewEditorSideMenu from './PreviewEditorSideMenu';
import '../Editor/Editor.scss';

const PreviewTab = ({ context }: { context: ExecuteContext }): JSX.Element => {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const { activePolicy } = useAppSelector((state: RootState) => state.policyDetail);
  const { activeTransaction } = useAppSelector((state: RootState) => state.transactionDetail);
  const { documentTypesList } = useAppSelector((state: RootState) => state.miscellaneous);

  // TODO output should be a string...
  const getFirstDoc = (documentTypeIds: string[]) => {
    for (let i = 0; i < documentTypesList.length; i++) {
      if (documentTypeIds.includes(documentTypesList[i].id)) {
        return documentTypesList[i].id;
      }
    }
  };

  const contextId: string | undefined = context === ExecuteContext.Policy ? activePolicy.id : activeTransaction.id;

  useEffect(() => {
    const getData = context === ExecuteContext.Policy ? getPolicy : getLandlordTransaction;
    dispatch(getData({ id })).then((response: any) => {
      if (response.meta.requestStatus === 'fulfilled') {
        const setCrossRef = context === ExecuteContext.Policy ? setCrossRefInPolicy : setCrossRefInTransaction;
        dispatch(setCrossRef({ id }));
      }
    });
    const activeContext: PolicyProps | TransactionProps =
      context === ExecuteContext.Policy ? activePolicy : activeTransaction;
    const docId: string | null | undefined =
      activeContext.documentTypeIds.length !== 0 ? getFirstDoc(activeContext.documentTypeIds) : null;
    dispatch(updateDocTypeId(docId));
  }, [dispatch]);

  const transactionPage: boolean = context === ExecuteContext.Transaction;

  const contextName: string | null = transactionPage ? activeTransaction?.identifier : activePolicy?.name;

  const previewDropdownItems: DropdownItem[] = [
    {
      key: '1',
      label: 'Generate HTML',
      dataTest: 'preview-html-button',
      icon: Icons.Html,
      onClick: () =>
        dispatch(
          getPdfHtml({
            context: context,
            contextId,
            documentTypeId: null,
          }),
        ),
    },
    {
      key: '2',
      label: 'Generate PDF',
      dataTest: 'generate-pdf-button',
      icon: Icons.Pdf,
      onClick: () =>
        dispatch(
          getPDFDocument({
            context: context,
            contextId,
            contextName,
          }),
        ),
    },
  ];

  const editorAndSidePanel = (
    <div className="editor-container p-sm">
      <div className="d-flex editor-top-left">
        <Button
          className="btn grey-outline"
          onClick={() => setIsFullScreen(!isFullScreen)}
          data-test={isFullScreen == true ? 'go-back-button' : 'full-screen-button'}
        >
          {isFullScreen ? 'GO BACK' : 'FULLSCREEN'}
        </Button>

        <DropdownMenu
          className="preview-menu-dropdown ui button btn grey-outline m-l-s"
          data-test="preview-dropdown"
          dropdownText="Preview"
          dataTest="preview-dropdown-button"
          dropdownItems={previewDropdownItems}
        />
      </div>

      <PreviewEditorMain context={context} />
      <PreviewEditorSideMenu context={context} />
    </div>
  );

  return (
    <TabContainer tabTitle={'Preview'}>
      <CreateDiscussionModal />
      {isFullScreen ? <FullScreen>{editorAndSidePanel}</FullScreen> : editorAndSidePanel}
    </TabContainer>
  );
};

export default PreviewTab;
