import {
  CDuration,
  CValuationDataFloor,
  CValuationDataSpace,
  CValuationReport,
} from 'common/_classes/valuationReport2';
import moment from 'moment';
import React from 'react';
import { Popup } from 'semantic-ui-react';
import { isArray } from 'lodash';
import Badge, { BadgeColor } from 'atoms/Badge';
import { filterBooleanElementBody } from 'atoms/TableReactPrime/columnTemplates';
import {
  BUILDING_GRADE_OPTIONS,
  CHARACTERISTICS_OPTIONS,
  EXPANSION_RIGHT_RENT_ASSESSMENT_OPTIONS,
  HANDOVER_CONDITION_OPTIONS,
  LANDLORD_APPROVAL_TYPE_OPTIONS,
  LEASE_TYPE_OPTIONS,
  PERMITTED_USE_OPTIONS,
  REFUSAL_RIGHT_RENT_ASSESSMENT_OPTIONS,
  RENT_REVIEW_METHOD_OPTIONS,
  RIGHT_RESTRICTION_OPTIONS,
  SHARE_RIGHT_RESTRICTION_OPTIONS,
  SPECIFIC_USE_OPTIONS,
  WHOLE_OR_PART_OPTIONS,
} from 'utils/UI';
import { conditionalClassName } from 'utils/tsHelper';
import { getDateOfAddedDays } from 'utils/utils-date';
import { addSpaceOrComma } from 'utils/utils-number';
import { replaceUnderscoreWithSpace } from 'utils/utils-string';
import { PremisesFilterValue } from './components/RowTemplate/RowFilterPopup/RowFilterContent/PremiseFilter';
import { applyStringFilter } from './components/RowTemplate/RowFilterPopup/RowFilterContent/StringFilter';
import { DataType, FilterMode, RowAction, RowProps } from './components/RowTemplate/RowProps';

const popupPostion = 'top center';

const buildingGradeBody = (item: CValuationReport): string | JSX.Element[] => {
  const {
    data: { buildingFeatures },
  } = item;

  return buildingFeatures.useType
    ? buildingFeatures.useType.map((useType, index) => (
        <Badge
          badgeColor={BadgeColor.GRAY}
          key={`building-usetype-${item.id}-${index}`}
          className={conditionalClassName(index !== 0, 'm-l-xs')}
        >
          {replaceUnderscoreWithSpace(useType)}
        </Badge>
      ))
    : '';
};

const valuationDataFloorMap = (floors: CValuationDataFloor[]) => {
  return floors.map((floor, index) => (
    <div
      key={floor.id}
      className={`d-flex flex-gap-s align-center ${conditionalClassName(index !== 0, 'm-t-s p-t-s border-top')}`}
    >
      <span className="width-auto">{floor.name}</span>

      <div className="d-flex flex-gap-xs">
        {floor.spaces.map(space => (
          <Badge
            badgeColor={BadgeColor.GRAY}
            key={`unit-characteristics-${space.id}-${index}`}
            className={conditionalClassName(index !== 0, 'm-l-xxs')}
          >
            {space.name}
          </Badge>
        ))}
      </div>
    </div>
  ));
};

const premises = (item: CValuationReport): JSX.Element[] => {
  const {
    data: {
      premises: { floors },
    },
  } = item;

  return valuationDataFloorMap(floors);
};

const unitView = (item: CValuationReport): JSX.Element[] => {
  const {
    data: {
      premisesCharacteristics: { views: floorViews },
    },
  } = item;

  return floorViews.map((floorView, index) => {
    const firstItem = index === 0;
    return (
      <div
        key={`unit-view-${floorView.id}-${index}`}
        className={`d-flex flex-gap-s align-center ${conditionalClassName(!firstItem, 'm-t-s p-t-s border-top')}`}
      >
        <span className="width-auto">{floorView.floorName}</span>

        <div className="d-flex-column flex-gap-xs align-center">
          {floorView.spaces.map((spaceView, index) => (
            <div
              className="d-flex align-center"
              key={`space-view-${spaceView.id}-${index}`}
            >
              <span className="m-r-xs width-auto">{spaceView.spaceName}</span>
              {spaceView.characteristics.map((characteristic, index) => (
                <Badge
                  badgeColor={BadgeColor.GRAY}
                  key={`unit-characteristics-${spaceView.id}-${index}`}
                  className={conditionalClassName(!firstItem, 'm-l-xxs')}
                >
                  {replaceUnderscoreWithSpace(characteristic)}
                </Badge>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  });
};

const fillString = (value: any, stringFormat: (value: any) => string) => {
  return value === null ? '' : stringFormat(value);
};

export const rentReviewDetails = (item: CValuationReport): JSX.Element[] => {
  let rentReview = [] as any;
  if (isArray(item)) {
    rentReview = item;
  } else {
    rentReview = item.data.rentReview;
  }

  return rentReview.map((review: any, index: number) => {
    const firstItem = rentReview.length === 1 ? false : index === 0;
    const secondItem = index === 1;

    const reviewDate = moment(review.reviewDate).format('DD/MM/YYYY');
    const startDate = moment(getDateOfAddedDays(review.reviewDate, 1)).format('DD/MM/YYYY');

    const unformattedEndDate =
      index + 1 < rentReview.length ? rentReview[index + 1].reviewDate : item.data?.term?.endDate;
    const endDate = moment(unformattedEndDate).format('DD/MM/YYYY');

    const reviewType = review.type ? review.type.toUpperCase() : review.type;
    const method = RENT_REVIEW_METHOD_OPTIONS.find(option => option.value === reviewType)?.text;

    return firstItem ? (
      <React.Fragment key={`review-details-${index}`}></React.Fragment>
    ) : (
      <Popup
        hoverable={true}
        position={popupPostion}
        key={`review-details-${index}`}
        trigger={
          <div
            className={`d-flex flex-gap-s align-center ${conditionalClassName(!secondItem, 'm-t-s p-t-s border-top')}`}
          >
            <span className="width-auto">{index}</span>

            <div className="d-flex align-center">
              {reviewDate}, {method}
              {`
                ${fillString(review.cap, cap => `, ${cap} (%) cap`)}
                ${fillString(review.collar, collar => `, ${collar} (%) collar`)} 
                ${fillString(review.increment, increment => `, ${increment}(%) increment`)} 
                ${fillString(review.faceRentPsfPcm, faceRentPsfPcm => `, ${addSpaceOrComma(faceRentPsfPcm, false)} (HK$/sf/mo)`)}
              `}
            </div>
          </div>
        }
        content={
          <div className="d-flex-column flex-gap-s">
            <div>
              Review date: <strong>{reviewDate}</strong>
            </div>
            <div>
              Start Date: <strong>{startDate}</strong>
            </div>
            <div>
              End Date: <strong>{endDate}</strong>
            </div>
            <div>
              Review Method: <strong>{method}</strong>
            </div>

            {review.cap ? (
              <div>
                Cap: <strong>{review.cap.toString()}%</strong>
              </div>
            ) : (
              <></>
            )}

            {review.collar ? (
              <div>
                Collar: <strong>{review.collar.toString()}%</strong>
              </div>
            ) : (
              <></>
            )}

            {review.increment ? (
              <div>
                Increment: <strong>{review.increment.toString()}%</strong>
              </div>
            ) : (
              <></>
            )}

            {review.faceRentPsfPcm ? (
              <div>
                Face Rent (psf pcm):{' '}
                <strong>{addSpaceOrComma(review.faceRentPsfPcm.toString(), false)} (HK$/sf/mo) </strong>
              </div>
            ) : (
              <></>
            )}
          </div>
        }
      />
    );
  });
};

const rentAgreementDetails = (item: CValuationReport): JSX.Element[] => {
  const {
    data: { rentReview },
  } = item;

  return rentReview.map((review, index) => {
    const firstItem = index === 0;
    const secondItem = index === 1;

    const rentAgreementDate = review.rentAgreementDate ? moment(review.rentAgreementDate).format('DD/MM/YYYY') : null;
    const rentDeterminationDate = review.rentDeterminationDate
      ? moment(review.rentDeterminationDate).format('DD/MM/YYYY')
      : null;
    const vacancyRate = review.vacancyRate ? review.vacancyRate.toString() : null;
    const faceRentPsfPcm = review.faceRentPsfPcm ? review.faceRentPsfPcm.toString() : null;
    const effectiveRentPsfPcm = review.effectiveRentPsfPcm ? review.effectiveRentPsfPcm.toString() : null;

    return firstItem ? (
      <React.Fragment key={`rent-agreement-${index}`}></React.Fragment>
    ) : (
      <Popup
        hoverable={true}
        position={popupPostion}
        key={`rent-agreement-${index}`}
        trigger={
          <div
            className={`d-flex flex-gap-s align-center ${conditionalClassName(!secondItem, 'm-t-s p-t-s border-top')}`}
          >
            <span className="width-auto">{index}</span>

            <div className="d-flex align-center">
              {`
              ${fillString(rentAgreementDate, _ => `${rentAgreementDate}`)} 
              ${fillString(rentDeterminationDate, _ => `${rentAgreementDate ? ', ' : ''}${rentDeterminationDate}`)}
              ${fillString(vacancyRate, vacancyRate => `${rentAgreementDate || rentDeterminationDate ? ', ' : ''}${vacancyRate}%`)} 
              ${fillString(faceRentPsfPcm, faceRentPsfPcm => `${rentAgreementDate || rentDeterminationDate || vacancyRate ? ',' : ''} ${addSpaceOrComma(faceRentPsfPcm, false)} (HK$/sf/mo)`)} 
              ${fillString(effectiveRentPsfPcm, effectiveRentPsfPcm => `${rentAgreementDate || rentDeterminationDate || vacancyRate || faceRentPsfPcm ? ',' : ''} ${addSpaceOrComma(effectiveRentPsfPcm, false)} (HK$/sf/mo)`)} 
              `}
            </div>
          </div>
        }
        content={
          <div className="d-flex-column flex-gap-s">
            {rentAgreementDate ? (
              <div>
                Rent agreement date: <strong>{rentAgreementDate}</strong>
              </div>
            ) : (
              <></>
            )}

            {rentDeterminationDate ? (
              <div>
                Rent determination date: <strong>{rentDeterminationDate}</strong>
              </div>
            ) : (
              <></>
            )}

            {vacancyRate ? (
              <div>
                Vacancy rate: <strong>{vacancyRate}%</strong>
              </div>
            ) : (
              <></>
            )}

            {faceRentPsfPcm ? (
              <div>
                Face Rent (psf pcm): <strong>{addSpaceOrComma(faceRentPsfPcm, false)} (HK$/sf/mo) </strong>
              </div>
            ) : (
              <></>
            )}

            {effectiveRentPsfPcm ? (
              <div>
                Face Rent (psf pcm):
                <strong>{addSpaceOrComma(effectiveRentPsfPcm, false)} (HK$/sf/mo) </strong>
              </div>
            ) : (
              <></>
            )}
          </div>
        }
      />
    );
  });
};

export const renewalRightsBody = (item: CValuationReport): JSX.Element => {
  let renewFlag, renewalRights;
  if (isArray(item)) {
    if (item.length !== 0) {
      renewFlag = true;
    }
    renewalRights = item;
  } else {
    renewalRights = item.data.specialRights.renewalRights;
    renewFlag = item.data.specialRights.renewFlag;
  }

  return (
    <Popup
      hoverable={true}
      position="top left"
      trigger={<div>{filterBooleanElementBody(renewFlag as boolean)}</div>}
      content={renewalRights.map((right, index) => {
        const wholeOrPart = right.wholeOrPart ? right.wholeOrPart.toUpperCase() : right.wholeOrPart;
        const rightType = right.type ? right.type.toUpperCase() : right.type;

        const exercisability = WHOLE_OR_PART_OPTIONS.find(option => option.value === wholeOrPart)?.text;
        const method = RENT_REVIEW_METHOD_OPTIONS.find(option => option.value === rightType)?.text;

        const cap = right.cap ? right.cap.toString() : null;
        const collar = right.collar ? right.collar.toString() : null;
        const increment = right.increment ? right.increment.toString() : null;
        const rent = right.rent ? addSpaceOrComma(right.rent.toString(), false) : null;

        return (
          <div
            className={`d-flex ${conditionalClassName(index !== 0, 'm-t-sm')}`}
            style={{ minWidth: '30rem' }}
          >
            <div
              className="m-r-xs"
              style={{ whiteSpace: 'nowrap' }}
            >
              Option {index + 1}:
            </div>

            <div className="d-flex-column flex-gap-xxs">
              <span>
                Duration: <strong>{moment(right.startDate).format('DD/MM/YYYY')}</strong> to{' '}
                <strong>{moment(right.endDate).format('DD/MM/YYYY')}</strong>
              </span>

              <span>
                Term length: <strong>{right.renewalTermLength} months</strong>
              </span>

              {exercisability ? (
                <span>
                  Exercisable in: <strong>{exercisability}</strong>
                </span>
              ) : (
                <></>
              )}

              <span style={{ whiteSpace: 'nowrap' }}>
                Rent determination method: <strong>{method}</strong>
              </span>

              {cap ? (
                <span>
                  Cap: <strong>{cap}%</strong>
                </span>
              ) : (
                <></>
              )}

              {collar ? (
                <span>
                  Collar: <strong>{collar}%</strong>
                </span>
              ) : (
                <></>
              )}

              {increment ? (
                <span>
                  Increment: <strong>{increment}%</strong>
                </span>
              ) : (
                <></>
              )}

              {rent ? (
                <span>
                  Fixed rent (psf pcm): <strong>{rent} (HK$/sf/mo)</strong>
                </span>
              ) : (
                <></>
              )}

              {right.noticePeriodMonths ? (
                <span>
                  Notice period:{' '}
                  <strong>
                    {right.noticePeriodMonths} month{right.noticePeriodMonths > 1 ? 's' : ''}
                  </strong>
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>
        );
      })}
      disabled={!renewFlag}
    />
  );
};

const surrenderRightsBody = (item: CValuationReport): JSX.Element => {
  const {
    data: {
      specialRights: { surrenderFlag, surrenderRights },
    },
  } = item;

  return (
    <Popup
      hoverable={true}
      position="top left"
      trigger={<div>{filterBooleanElementBody(surrenderFlag)}</div>}
      content={
        surrenderRights ? (
          (() => {
            const exercisability = WHOLE_OR_PART_OPTIONS.find(
              option => option.value === surrenderRights.wholeOrPart,
            )?.text;

            const earliestDate = surrenderRights.earliestDate
              ? moment(surrenderRights.earliestDate).format('DD/MM/YYYY')
              : null;
            const latestDate = surrenderRights.latestDate
              ? moment(surrenderRights.latestDate).format('DD/MM/YYYY')
              : null;

            return (
              <div className="d-flex-column flex-gap-xxs">
                {exercisability ? (
                  <span>
                    Exercisable in: <strong>{exercisability}</strong>
                  </span>
                ) : (
                  <></>
                )}

                {earliestDate ? (
                  <span>
                    Earliest date: <strong>{earliestDate}</strong>
                  </span>
                ) : (
                  <></>
                )}

                {latestDate ? (
                  <span>
                    Latest date: <strong>{latestDate}</strong>
                  </span>
                ) : (
                  <></>
                )}

                {surrenderRights.noticePeriodMonths ? (
                  <span>
                    Notice period:{' '}
                    <strong>
                      {surrenderRights.noticePeriodMonths} month{surrenderRights.noticePeriodMonths > 1 ? 's' : ''}
                    </strong>
                  </span>
                ) : (
                  <></>
                )}

                {surrenderRights.compensationFormula ? (
                  <span>
                    Compensation formula: <strong>{surrenderRights.compensationFormula}</strong>
                  </span>
                ) : (
                  <></>
                )}
              </div>
            );
          })()
        ) : (
          <></>
        )
      }
      disabled={!surrenderFlag}
    />
  );
};

const expansionRightsBody = (item: CValuationReport): JSX.Element => {
  const {
    data: {
      specialRights: { expansionFlag, expansionRights },
    },
  } = item;

  return (
    <Popup
      hoverable={true}
      position="top left"
      trigger={<div>{filterBooleanElementBody(expansionFlag)}</div>}
      content={
        expansionRights ? (
          (() => {
            const exercisability = WHOLE_OR_PART_OPTIONS.find(
              option => option.value === expansionRights.wholeOrPart,
            )?.text;

            const rentAssessment = EXPANSION_RIGHT_RENT_ASSESSMENT_OPTIONS.find(
              option => option.value === expansionRights.rentAssessment,
            )?.text;

            const latestDate = expansionRights.latestDate
              ? moment(expansionRights.latestDate).format('DD/MM/YYYY')
              : null;

            return (
              <div
                className="d-flex-column flex-gap-s"
                style={{ minWidth: '30rem' }}
              >
                <span>
                  Building: <strong>{expansionRights.premises.buildingName}</strong>
                </span>

                <span className="d-flex flex-gap-s">
                  Floors: <strong>{valuationDataFloorMap(expansionRights.premises.floors)}</strong>
                </span>

                {exercisability ? (
                  <span>
                    Exercisable in: <strong>{exercisability}</strong>
                  </span>
                ) : (
                  <></>
                )}

                <span>
                  Rent assessment: <strong>{rentAssessment}</strong>
                </span>

                {latestDate ? (
                  <span>
                    Exercisable until: <strong>{latestDate}</strong>
                  </span>
                ) : (
                  <></>
                )}
              </div>
            );
          })()
        ) : (
          <></>
        )
      }
      disabled={!expansionFlag}
    />
  );
};

export const refusalRightsBody = (item: CValuationReport): JSX.Element => {
  let refusalFlag,
    refusalRights,
    justContent = false;
  if (item?.data) {
    refusalFlag = item.data.specialRights.refusalFlag;
    refusalRights = item.data.specialRights.refusalRights;
  } else {
    refusalFlag = true;
    refusalRights = item as any;
    justContent = true;
  }

  if (!refusalRights) return filterBooleanElementBody(refusalFlag);

  const wholeOrPart = refusalRights?.wholeOrPart ? refusalRights.wholeOrPart.toUpperCase() : refusalRights?.wholeOrPart;
  const rentAssessmentValue = refusalRights?.rentAssessment
    ? refusalRights?.rentAssessment.toUpperCase()
    : refusalRights?.rentAssessment;

  const buildingName = refusalRights?.premises ? refusalRights.premises.buildingName : refusalRights.building;
  const floors = refusalRights?.premises ? refusalRights.premises.floors : refusalRights.floors;

  const content = refusalRights ? (
    (() => {
      const exercisability = WHOLE_OR_PART_OPTIONS.find(option => option.value === wholeOrPart)?.text;

      const rentAssessment = REFUSAL_RIGHT_RENT_ASSESSMENT_OPTIONS.find(
        option => option.value === rentAssessmentValue,
      )?.text;

      const exercisableFrom = refusalRights.exercisableFrom
        ? moment(refusalRights.exercisableFrom).format('DD/MM/YYYY')
        : null;

      const priority =
        refusalRights.priority.length > 0
          ? refusalRights.priority.map((priority: any, index: number) => (
              <Badge
                badgeColor={BadgeColor.GRAY}
                key={`priority-${index}`}
                className={conditionalClassName(index !== 0, 'm-l-xs')}
              >
                {replaceUnderscoreWithSpace(priority)}
              </Badge>
            ))
          : null;

      return (
        <div
          className="d-flex-column rofo-option-content flex-gap-s"
          style={{ minWidth: '30rem' }}
        >
          <span>
            Building: <strong>{buildingName}</strong>
          </span>

          <span className="d-flex flex-gap-s">
            Floors: <strong>{valuationDataFloorMap(floors)}</strong>
          </span>

          {exercisability ? (
            <span>
              Exercisable in: <strong>{exercisability}</strong>
            </span>
          ) : (
            <></>
          )}

          {exercisableFrom ? (
            <span>
              Exercisable from: <strong>{exercisableFrom}</strong>
            </span>
          ) : (
            <></>
          )}

          {priority ? <span>Priority: {priority}</span> : <></>}

          <span>
            Rent assessment: <strong>{rentAssessment}</strong>
          </span>

          <span>
            Reoffer to tenant before leasing at lower rent:{' '}
            <strong>{refusalRights.reofferBeforeLeasingAtLowerRent ? 'Yes' : 'No'}</strong>
          </span>
        </div>
      );
    })()
  ) : (
    <></>
  );

  if (justContent) {
    return content;
  }

  return (
    <Popup
      hoverable={true}
      position="top left"
      trigger={<div>{filterBooleanElementBody(refusalFlag)}</div>}
      content={content}
      disabled={!refusalFlag}
    />
  );
};

const subletRightsBody = (item: CValuationReport): JSX.Element => {
  const {
    data: {
      specialRights: { subletFlag, subletRights },
    },
  } = item;

  return (
    <Popup
      hoverable={true}
      position="top left"
      trigger={<div>{filterBooleanElementBody(subletFlag)}</div>}
      content={
        subletRights ? (
          (() => {
            const exercisability = WHOLE_OR_PART_OPTIONS.find(
              option => option.value === subletRights.wholeOrPart,
            )?.text;

            const restrictedTo = RIGHT_RESTRICTION_OPTIONS.find(
              option => option.value === subletRights.restrictedTo,
            )?.text;

            const landlordApproval = LANDLORD_APPROVAL_TYPE_OPTIONS.find(
              option => option.value === subletRights.landlordApproval,
            )?.text;

            const premisePercentage = subletRights.premisePercentage ? subletRights.premisePercentage.toString() : null;

            const landlordProfitShare = subletRights.landlordProfitShare
              ? subletRights.landlordProfitShare.toString()
              : null;

            return (
              <div className="d-flex-column flex-gap-s">
                {exercisability ? (
                  <span>
                    Exercisable in: <strong>{exercisability}</strong>
                  </span>
                ) : (
                  <></>
                )}

                {premisePercentage ? (
                  <span>
                    Premise percentage sublettable: <strong>{premisePercentage}%</strong>
                  </span>
                ) : (
                  <></>
                )}

                {restrictedTo ? (
                  <span>
                    Restricted to: <strong>{restrictedTo}</strong>
                  </span>
                ) : (
                  <></>
                )}

                {landlordApproval ? (
                  <span>
                    Landlord approval: <strong>{landlordApproval}</strong>
                  </span>
                ) : (
                  <></>
                )}

                {landlordProfitShare ? (
                  <span>
                    Profit to landlord: <strong>{landlordProfitShare}%</strong>
                  </span>
                ) : (
                  <></>
                )}
              </div>
            );
          })()
        ) : (
          <></>
        )
      }
      disabled={!subletFlag}
    />
  );
};

const shareRightsBody = (item: CValuationReport): JSX.Element => {
  const {
    data: {
      specialRights: { shareFlag, shareRights },
    },
  } = item;

  const disabled = !shareFlag || !shareRights || shareRights.restrictedTo === null;

  return (
    <Popup
      hoverable={true}
      position="top left"
      trigger={<div>{filterBooleanElementBody(shareFlag)}</div>}
      content={
        shareRights && shareRights.restrictedTo ? (
          (() => {
            const restrictedTo = SHARE_RIGHT_RESTRICTION_OPTIONS.find(
              option => option.value === shareRights.restrictedTo,
            )?.text;

            return (
              <div className="d-flex-column flex-gap-s">
                {restrictedTo ? (
                  <span>
                    Restricted to: <strong>{restrictedTo}</strong>
                  </span>
                ) : (
                  <></>
                )}
              </div>
            );
          })()
        ) : (
          <></>
        )
      }
      disabled={disabled}
    />
  );
};

const saleAndRedevelopmentBody = (item: CValuationReport): JSX.Element => {
  const {
    data: {
      specialRights: { saleAndRedevelopmentFlag, saleAndRedevelopmentRights },
    },
  } = item;

  const earliestDate = saleAndRedevelopmentRights?.earliestDate
    ? moment(saleAndRedevelopmentRights?.earliestDate).format('DD/MM/YYYY')
    : null;

  const compensationFormula = saleAndRedevelopmentRights?.compensationFormula;
  const noticePeriodMonths = saleAndRedevelopmentRights?.noticePeriodMonths;

  const disabled = !saleAndRedevelopmentFlag || !compensationFormula || !noticePeriodMonths;

  return (
    <Popup
      hoverable={true}
      position="top left"
      trigger={<div>{filterBooleanElementBody(saleAndRedevelopmentFlag)}</div>}
      content={
        saleAndRedevelopmentRights ? (
          (() => {
            return (
              <div className="d-flex-column flex-gap-xxs">
                {earliestDate ? (
                  <span>
                    Earliest date: <strong>{earliestDate}</strong>
                  </span>
                ) : (
                  <></>
                )}

                {noticePeriodMonths ? (
                  <span>
                    Notice period:{' '}
                    <strong>
                      {noticePeriodMonths} month{noticePeriodMonths > 1 ? 's' : ''}
                    </strong>
                  </span>
                ) : (
                  <></>
                )}

                {compensationFormula ? (
                  <span>
                    Compensation formula: <strong>{compensationFormula}</strong>
                  </span>
                ) : (
                  <></>
                )}
              </div>
            );
          })()
        ) : (
          <></>
        )
      }
      disabled={disabled}
    />
  );
};

const AssignmentRightsBody = (item: CValuationReport): JSX.Element => {
  const {
    data: {
      specialRights: { assignmentFlag, assignmentRights },
    },
  } = item;

  return (
    <Popup
      hoverable={true}
      position="top left"
      trigger={<div>{filterBooleanElementBody(assignmentFlag)}</div>}
      content={
        assignmentRights ? (
          (() => {
            const exercisability = WHOLE_OR_PART_OPTIONS.find(
              option => option.value === assignmentRights.wholeOrPart,
            )?.text;

            const restrictedTo = RIGHT_RESTRICTION_OPTIONS.find(
              option => option.value === assignmentRights.restrictedTo,
            )?.text;

            const landlordApproval = LANDLORD_APPROVAL_TYPE_OPTIONS.find(
              option => option.value === assignmentRights.landlordApproval,
            )?.text;

            const premisePercentage = assignmentRights.premisePercentage
              ? assignmentRights.premisePercentage.toString()
              : null;

            return (
              <div className="d-flex-column flex-gap-s">
                {exercisability ? (
                  <span>
                    Exercisable in: <strong>{exercisability}</strong>
                  </span>
                ) : (
                  <></>
                )}

                {premisePercentage ? (
                  <span>
                    Premise percentage assignable: <strong>{premisePercentage}%</strong>
                  </span>
                ) : (
                  <></>
                )}

                {restrictedTo ? (
                  <span>
                    Restricted to: <strong>{restrictedTo}</strong>
                  </span>
                ) : (
                  <></>
                )}

                {landlordApproval ? (
                  <span>
                    Landlord approval: <strong>{landlordApproval}</strong>
                  </span>
                ) : (
                  <></>
                )}
              </div>
            );
          })()
        ) : (
          <></>
        )
      }
      disabled={!assignmentFlag}
    />
  );
};

const partAndWholeFloorCounts = ({
  data: {
    premisesCharacteristics: { partFloorsCount, wholeFloorsCount },
  },
}: CValuationReport): string => {
  let wholeFloor = '';
  let partFloor = '';

  if (wholeFloorsCount) wholeFloor += `${wholeFloorsCount} whole floor${wholeFloorsCount > 1 ? 's' : ''}`;
  if (partFloorsCount) partFloor += `${partFloorsCount} part floor${partFloorsCount > 1 ? 's' : ''}`;

  return `${wholeFloor}${wholeFloor ? `, ${partFloor}` : partFloor}`;
};

/**
 * List of row items
 * contains basic details, filters status, sorting and templates
 */
export const RowConfigs: RowProps[] = [
  // Fundamentals
  {
    label: 'Building',
    field: 'data,buildingFeatures,name',
    columnClass: 'bg-orange-very-light-grayish',
    body: ({ data: { buildingFeatures } }: CValuationReport) => buildingFeatures.name,
    dataType: DataType.STRING,
  },
  {
    label: 'Premises',
    field: 'data,premises',
    columnClass: 'bg-orange-very-light-grayish',
    body: premises,
    dataType: DataType.PREMISE,
    hideSorting: true,
    filterFunction: (
      row: RowProps,
      {
        data: {
          premises: { floors },
        },
      }: CValuationReport,
      rowAction?: RowAction,
    ) => {
      if (!rowAction) return true;

      const filterValue: PremisesFilterValue = rowAction.filterValue;

      const spaceMatch = (space: CValuationDataSpace) => {
        const newRowConfig = { ...row, field: 'name' };
        const newRowAction = {
          ...rowAction,
          filterValue: filterValue.unitFilterValue,
          filterMode: filterValue.unitFilterMode,
        };

        return applyStringFilter(newRowConfig, space, newRowAction);
      };

      const floorMatch = (floor: CValuationDataFloor) => {
        const newRowConfig = { ...row, field: 'name' };
        const newRowAction = {
          ...rowAction,
          filterValue: filterValue.floorFilterValue,
          filterMode: filterValue.floorFilterMode,
        };

        return applyStringFilter(newRowConfig, floor, newRowAction) && floor.spaces.some(spaceMatch);
      };

      return floors.some(floorMatch);
    },
  },
  {
    label: 'tenant',
    field: 'data,tenant',
    columnClass: 'bg-orange-very-light-grayish',
    body: ({ data: { tenant } }: CValuationReport) => tenant,
    dataType: DataType.STRING,
  },
  {
    label: 'Premises size',
    field: 'data,premiseSize',
    body: ({ data: { premiseSize } }: CValuationReport) => {
      return premiseSize ? premiseSize.toNumber() : '';
    },
    columnClass: 'bg-orange-very-light-grayish',
    dataType: DataType.DECIMAL,
  },
  {
    label: 'Face rent (psf pcm)',
    field: 'data,faceRentPsfPcm',
    body: ({ data: { faceRentPsfPcm } }: CValuationReport) => faceRentPsfPcm?.toNumber() || null,
    columnClass: 'bg-orange-very-light-grayish',
    dataType: DataType.DECIMAL,
  },
  {
    label: 'Effective rent (psf pcm)',
    field: 'data,effectiveRentPsfPcm',
    body: ({ data: { effectiveRentPsfPcm } }: CValuationReport) => effectiveRentPsfPcm?.toNumber() || null,
    columnClass: 'bg-orange-very-light-grayish',
    dataType: DataType.DECIMAL,
  },
  // TERM
  {
    label: 'TERM',
    isHeading: true,
  },
  {
    label: 'Term start date',
    field: 'data,term,startDate',
    body: ({ data: { term } }: CValuationReport) => moment(term.startDate).format('DD/MM/YYYY'),
    dataType: DataType.DATE,
  },
  {
    label: 'Term end date',
    field: 'data,term,endDate',
    body: ({ data: { term } }: CValuationReport) => moment(term.endDate).format('DD/MM/YYYY'),
    dataType: DataType.DATE,
  },
  {
    label: 'Term length',
    field: 'data,term,duration',
    body: ({ data: { term } }: CValuationReport) => CDuration.toString(term.duration),
    dataType: DataType.DURATION,
  },
  // RENT FREE & EARLY OCCUPATION
  {
    label: 'RENT FREE & EARLY OCCUPATION',
    isHeading: true,
  },
  {
    label: 'Rent free period',
    field: 'data,rentFreeAndEarlyOccupation,rentFreePeriod',
    body: ({ data: { rentFreeAndEarlyOccupation } }: CValuationReport) => rentFreeAndEarlyOccupation.rentFreePeriod,
    dataType: DataType.NUMBER,
  },
  {
    label: 'Incentive rent free',
    field: 'data,rentFreeAndEarlyOccupation,incentiveRentFree',
    body: ({
      data: {
        rentFreeAndEarlyOccupation: { incentiveRentFree },
      },
    }: CValuationReport) => (incentiveRentFree ? CDuration.toString(incentiveRentFree) : null),
    dataType: DataType.DURATION,
  },
  {
    label: 'Early occupation license',
    field: 'data,rentFreeAndEarlyOccupation,incentiveRentFree',
    body: ({
      data: {
        rentFreeAndEarlyOccupation: { earlyOccupationLicense },
      },
    }: CValuationReport) => (earlyOccupationLicense ? CDuration.toString(earlyOccupationLicense) : null),
    dataType: DataType.DURATION,
  },
  // RENT & RENT REVIEW
  {
    label: 'RENT & RENT REVIEW',
    isHeading: true,
  },
  {
    label: 'Date initial rent first agreed',
    field: 'data,rentReview,0,rentAgreementDate',
    body: ({ data: { rentReview } }: CValuationReport) =>
      rentReview[0].rentAgreementDate ? moment(rentReview[0].rentAgreementDate).format('DD/MM/YYYY') : '',
    dataType: DataType.DATE,
  },
  {
    label: 'Date binding contract was originally agreed',
    field: 'data,rentReview,0,rentDeterminationDate',
    body: ({ data: { rentReview } }: CValuationReport) =>
      rentReview[0].rentDeterminationDate ? moment(rentReview[0].rentDeterminationDate).format('DD/MM/YYYY') : '',
    dataType: DataType.DATE,
  },
  {
    label: 'Vacancy rate at the time rent is agreed',
    field: 'data,rentReview,0,vacancyRate',
    body: ({ data: { rentReview } }: CValuationReport) =>
      rentReview[0].vacancyRate ? rentReview[0].vacancyRate.toNumber() : '',
    dataType: DataType.DECIMAL,
  },
  {
    label: 'Rent review details',
    field: 'data,rentReview',
    body: rentReviewDetails,
    dataType: DataType.STRING,
    hideSorting: true,
    hideFilter: true,
  },
  {
    label: 'Rents agreed/determined',
    field: 'data,rentReview',
    body: rentAgreementDetails,
    dataType: DataType.DURATION,
    hideSorting: true,
    hideFilter: true,
  },
  // SECURITY
  {
    label: 'SECURITY',
    isHeading: true,
  },
  {
    label: 'Security (# months)',
    field: 'data,security,months',
    body: ({ data: { security } }: CValuationReport) => security.months,
    dataType: DataType.NUMBER,
  },
  {
    label: 'Security (HKD$)',
    field: 'data,security,amount',
    body: ({
      data: {
        security: { amount },
      },
    }: CValuationReport) => (amount !== null ? addSpaceOrComma(amount.toString(), false) : amount),
    dataType: DataType.DECIMAL,
  },
  // SPECIAL RIGHTS
  {
    label: 'SPECIAL RIGHTS',
    isHeading: true,
  },
  {
    label: 'Option to renew',
    field: 'data,specialRights,renewFlag',
    body: renewalRightsBody,
    dataType: DataType.BOOLEAN,
  },
  {
    label: 'Right to surrender',
    field: 'data,specialRights,surrenderFlag',
    body: surrenderRightsBody,
    dataType: DataType.BOOLEAN,
  },
  {
    label: 'Expansion right',
    field: 'data,specialRights,expansionFlag',
    body: expansionRightsBody,
    dataType: DataType.BOOLEAN,
  },
  {
    label: 'Right to sublet',
    field: 'data,specialRights,subletFlag',
    body: subletRightsBody,
    dataType: DataType.BOOLEAN,
  },
  {
    label: 'Right to share',
    field: 'data,specialRights,shareFlag',
    body: shareRightsBody,
    dataType: DataType.BOOLEAN,
  },
  {
    label: 'Right to assign',
    field: 'data,specialRights,assignmentFlag',
    body: AssignmentRightsBody,
    dataType: DataType.BOOLEAN,
  },
  {
    label: 'Right to refuse',
    field: 'data,specialRights,refusalFlag',
    body: refusalRightsBody,
    dataType: DataType.BOOLEAN,
  },
  {
    label: 'Sale & redevelopment',
    field: 'data,specialRights,saleAndRedevelopmentFlag',
    body: saleAndRedevelopmentBody,
    dataType: DataType.BOOLEAN,
  },
  // BUILDING CHARACTERISTICS
  {
    label: 'BUILDING CHARACTERISTICS',
    isHeading: true,
  },
  {
    label: 'Building type',
    field: 'useType',
    body: buildingGradeBody,
    dataType: DataType.LIST,
    options: BUILDING_GRADE_OPTIONS,
    hideSorting: true,
  },
  {
    label: 'Covered access to MTR',
    field: 'data,buildingFeatures,coveredAccessToMtr',
    body: ({ data: { buildingFeatures } }: CValuationReport) =>
      filterBooleanElementBody(buildingFeatures.coveredAccessToMtr),
    dataType: DataType.BOOLEAN,
  },
  {
    label: 'Central AC',
    field: 'data,buildingFeatures,centralAc',
    body: ({ data: { buildingFeatures } }: CValuationReport) => filterBooleanElementBody(buildingFeatures.centralAc),
    dataType: DataType.BOOLEAN,
  },
  {
    label: 'Curtain wall',
    field: 'data,buildingFeatures,curtainWall',
    body: ({ data: { buildingFeatures } }: CValuationReport) => filterBooleanElementBody(buildingFeatures.curtainWall),
    dataType: DataType.BOOLEAN,
  },
  // PREMISES CHARACTERISTICS
  {
    label: 'PREMISES CHARACTERISTICS',
    isHeading: true,
  },
  {
    label: 'Permitted use',
    field: 'data,premisesCharacteristics,permittedUse',
    body: ({ data: { premisesCharacteristics } }: CValuationReport) =>
      premisesCharacteristics.permittedUse ? (
        <Badge badgeColor={BadgeColor.GRAY}>{replaceUnderscoreWithSpace(premisesCharacteristics.permittedUse)}</Badge>
      ) : (
        ''
      ),
    dataType: DataType.LIST,
    options: PERMITTED_USE_OPTIONS,
    hideSorting: true,
  },
  {
    label: 'Specific use',
    field: 'data,premisesCharacteristics,specificUse',
    body: ({ data: { premisesCharacteristics } }: CValuationReport) =>
      premisesCharacteristics.specificUse ? (
        <Badge badgeColor={BadgeColor.GRAY}>{replaceUnderscoreWithSpace(premisesCharacteristics.specificUse)}</Badge>
      ) : (
        ''
      ),
    dataType: DataType.LIST,
    options: SPECIFIC_USE_OPTIONS,
    hideSorting: true,
  },
  {
    label: 'Handover condition',
    field: 'data,premisesCharacteristics,handoverCondition',
    body: ({ data: { premisesCharacteristics } }: CValuationReport) =>
      premisesCharacteristics.handoverCondition ? (
        <Badge badgeColor={BadgeColor.GRAY}>
          {replaceUnderscoreWithSpace(premisesCharacteristics.handoverCondition)}
        </Badge>
      ) : (
        ''
      ),
    dataType: DataType.LIST,
    options: HANDOVER_CONDITION_OPTIONS,
    hideSorting: true,
  },
  {
    label: '# whole floors, # part floors',
    field: 'wholeFloorsCount, partFloorsCount',
    body: partAndWholeFloorCounts,
    dataType: DataType.NUMBER,
    hideSorting: true,
    hideFilter: true,
  },
  {
    label: 'View',
    field: 'useType',
    body: unitView,
    dataType: DataType.LIST,
    options: CHARACTERISTICS_OPTIONS,
    hideSorting: true,
    hideFilter: true,
  },
  {
    label: '% efficiency',
    field: 'data,premisesCharacteristics,efficiency',
    body: ({ data: { premisesCharacteristics } }: CValuationReport) => premisesCharacteristics.efficiency.toNumber(),
    dataType: DataType.DECIMAL,
  },
  // OTHER
  {
    label: 'OTHER',
    isHeading: true,
  },
  {
    label: 'Special remarks',
    field: 'data,others,specialRemarks',
    body: ({ data: { others } }: CValuationReport) => others.specialRemarks,
    dataType: DataType.STRING,
  },
  {
    label: 'Tenant industry',
    field: 'data,others,tenantIndustry',
    body: ({ data: { others } }: CValuationReport) => others.tenantIndustry,
    dataType: DataType.STRING,
  },
  {
    label: 'Nature of lease',
    field: 'data,others,natureOfLease',
    body: ({ data: { others } }: CValuationReport) =>
      others.natureOfLease ? (
        <Badge badgeColor={BadgeColor.GRAY}>{replaceUnderscoreWithSpace(others.natureOfLease)}</Badge>
      ) : (
        ''
      ),
    dataType: DataType.LIST,
    options: LEASE_TYPE_OPTIONS,
    hideSorting: true,
  },
  {
    label: 'Agent Company',
    field: 'data,others,agentCompany',
    body: ({ data: { others } }: CValuationReport) => others.agentCompany,
    dataType: DataType.STRING,
  },
  {
    label: 'Agent Name',
    field: 'data,others,agentName',
    body: ({ data: { others } }: CValuationReport) => others.agentName,
    dataType: DataType.STRING,
  },
].map((row, index) => {
  return { ...row, id: index };
});
