import { createSlice, current } from '@reduxjs/toolkit';
import { PolicyListingClone } from 'common/_classes';
import { PropertyClone, TransactionChannelClone } from 'common/_classes';
import { TransactionMessageClone } from 'common/_classes/TransactionMessageClone';
import { WritableDraft } from 'immer/dist/internal';
import { toast } from 'react-toastify';
import { FLOOR_PORTION_OPTIONS, refreshPremises } from 'components/PremisesForm/utils-premiseForm';
import { ChangesBoxType } from 'views/transactions/CreatorViewer/Tabs/ApprovalTab/ApprovalChanges';
import { TRANSACTION_CONTRACT_VIEW_TABS_OFFSET } from 'views/transactions/CreatorViewer/Tabs/ContractView';
import { TRANSACTION_TABS_OFFSET } from 'views/transactions/CreatorViewer/Tabs/TransactionsTabs';
import Amendment from 'common/model/Amendment';
import DocumentWorkflowHistory from 'common/model/DocumentWorkflowHistory';
import Floor from 'common/model/Floor';
import TenantEntityWithJoin from 'common/model/TenantEntityWithJoin';
import TransactionAlertType from 'common/model/TransactionAlertType';
import TransactionAmendmentApproval from 'common/model/TransactionAmendmentApproval';
import TransactionApprovalAnswer from 'common/model/TransactionApprovalAnswer';
import TransactionChannelStatus from 'common/model/TransactionChannelStatus';
import TransactionChannelType from 'common/model/TransactionChannelType';
import TransactionDiscussionApproval from 'common/model/TransactionDiscussionApproval';
import TransactionHistoryGeneralStatus from 'common/model/TransactionHistoryGeneralStatus';
import TransactionParameterApproval from 'common/model/TransactionParameterApproval';
import TransactionProvisionApproval from 'common/model/TransactionProvisionApproval';
import ValidApprovalStates from 'common/model/ValidApprovalStates';
import ValidDocumentStatus from 'common/model/ValidDocumentStatus';
import { DropdownProps } from 'common/api/miscellaneous';
import { AnswerProps } from 'common/api/policies';
import { ContentClone } from 'common/api/provisions';
import {
  ActiveTransactionChannel,
  DocHistoryProps,
  HistoryDocumentsProps,
  PremiseProps,
  TransactionAlertClone,
  TransactionProps,
  TransactionProvisionTemp,
  getTenantTransaction,
  updateTransactionMessage,
  uploadSendToTenantPDF,
} from 'common/api/transactions';
import {
  createAmendment,
  createAmendmentFromTransactionDiscussion,
  createTransaction,
  createTransactionAnswers,
  createTransactionChannel,
  createTransactionMessage,
  deleteAmendment,
  deleteContractOfTransaction,
  generateModificationNotes,
  getAmendment,
  getAutomatedTransactionIdentifier,
  getLandlordTransaction,
  getTransactionChannel,
  getTransactionContentsOnly,
  listAmendments,
  listLandlordAndTenantEntities,
  listPolicies,
  listPremises,
  listTransactionAlerts,
  listTransactionChannels,
  listTransactionMessages,
  listTransactionProvisions,
  prepTransactionAnswerOfImageTypeForUpload,
  prepTransactionDocForUpload,
  prepTransactionDocsForUpload,
  updateAmendment,
  updateTransaction,
  updateTransactionChannel,
  updateTransactionProvision,
  updateTransactionWithInitalization,
} from 'common/api/transactions';
import { createTransactionApprovalAnswers } from 'common/api/transactions/approvals/createTransactionApprovalAnswers';
import { createTransactionDiscussionApproval } from 'common/api/transactions/approvals/createTransactionDiscussionApproval';
import { createTransactionParameterApproval } from 'common/api/transactions/approvals/createTransactionParameterApproval';
import { deleteOldTransactionApprovalAnswers } from 'common/api/transactions/approvals/deleteOldTransactionApprovalAnswers';
import { listTransactionApprovalAnswers } from 'common/api/transactions/approvals/listTransactionApprovalAnswers';
import { listTransactionDiscussionApprovals } from 'common/api/transactions/approvals/listTransactionDiscussionApprovals';
import { listTransactionParameterApprovals } from 'common/api/transactions/approvals/listTransactionParameterApprovals';
import { listTransactionProvisionApprovals } from 'common/api/transactions/approvals/listTransactionProvisionApprovals';
import { updateTransactionApprovalAnswer } from 'common/api/transactions/approvals/updateTransactionApprovalAnswer';
import { updateTransactionApprovalAnswersState } from 'common/api/transactions/approvals/updateTransactionApprovalAnswersStatus';
import { updateTransactionDiscussionApproval } from 'common/api/transactions/approvals/updateTransactionDiscussionApproval';
import { updateTransactionParameterApproval } from 'common/api/transactions/approvals/updateTransactionParameterApproval';
import { updateTransactionProvisionApproval } from 'common/api/transactions/approvals/updateTransactionProvisionApproval';
import { createTransactionMessages } from 'common/api/transactions/createTransactionMessages';
import { getTransactionHistoryDocuments } from 'common/api/transactions/getTranstionHistoryDocuments';
import { setCrossRefInTransaction } from 'common/api/transactions/setCrossRefInTransaction';
import { translateToContract } from 'common/api/translatorsToContract';
import { DROPDOWN_OPTION } from 'utils/UI';
import { checkIfNotDefined } from 'utils/tsHelper';
import { provisionsDropdown } from 'utils/tsHelper';
import { LandlordsEntitiesProps } from 'utils/types/entities';
import { CounterOptions, getAnswers, updateIterationHelper } from 'utils/utils-answer';
import { getCurrentDateTimeISO } from 'utils/utils-date';

export interface DocumentWorkflowHistoryProps extends DocumentWorkflowHistory {
  userId: string;
  tenantId: string;
}

interface UploadPrepMultiple<T> {
  [key: string]: T;
}

export enum ApprovalView {
  Submitter = 'Submitter',
  Reviewer = 'Reviewer',
}

export interface ActiveAmendment extends Pick<Amendment, 'name' | 'content'> {
  id?: string;
  index: number | null;
  categoryId: string | null;
  documentTypeId: string | null;
}

interface TransactionDetailState {
  isLoading: boolean;
  activeTransaction: TransactionProps;
  notes: string | null;
  activeTabIndex: TRANSACTION_TABS_OFFSET;
  landlordEntitiesOptions: DROPDOWN_OPTION[];
  tenantEntitiesOptions: DROPDOWN_OPTION[];
  propertiesList: PropertyClone[];
  policiesList: PolicyListingClone[];
  policiesDropdownOptions: DROPDOWN_OPTION[];
  policyDocs: string[];
  transactionPremises: PremiseProps[];
  showParametersForm: boolean;
  percentageModalStatus: boolean;
  uploadPrepMultiple: UploadPrepMultiple<any> | null;
  transactionProvisionDropdown: DropdownProps[];
  modificationNotes: string | null;
  activeTransactionOldVersion: TransactionProps;
  activeContractViewTabOffset: TRANSACTION_CONTRACT_VIEW_TABS_OFFSET;
  activeAmendment: ActiveAmendment;
  transactionChannels: TransactionChannelClone[];
  transactionMessages: TransactionMessageClone[];
  automatedTransactionIdentifier: string | null;
  activeTransactionChannel: ActiveTransactionChannel;
  discussionMode: DiscussionMode;
  createDiscussionModalStatus: boolean;
  isSearchingTransactionChannels: boolean;
  activeTransactionProvisions: TransactionProvisionTemp[];
  activeTransactionContents: ContentClone[];
  activeTransactionAnswers: AnswerProps[];
  activeTransactionAmendments: Amendment[];
  activeGroupIds: string[];
  activeMessageId: string | null;
  approvalView: ApprovalView;
  transactionApprovalAnswers: TransactionApprovalAnswer[];
  transactionParameterApprovals: TransactionParameterApproval[];
  transactionDiscussionApprovals: TransactionDiscussionApproval[];
  transactionProvisionApprovals: TransactionProvisionApproval[];
  transactionAmendmentApprovals: TransactionAmendmentApproval[];
  uploadInProgress: string[];
}

const newTransaction: TransactionProps = {
  identifier: null,
  documentTypeIds: [],
  description: null,
  ownerId: null,
  tenantId: null,
  policyId: null,
  premises: refreshPremises(),
  historyGeneral: [],
  historyDocuments: [],
  translationInfo: null,
  regulatories: [],
  documents: [],
};

const newAmendment: ActiveAmendment = {
  categoryId: '',
  documentTypeId: '',
  index: null,
  name: '',
  content: '',
};

export enum DiscussionMode {
  List = 'List',
  View = 'View',
}

const newTransactionChannel: ActiveTransactionChannel = {
  amendmentId: null,
  nodeId: null,
  status: TransactionChannelStatus.Open,
  title: '',
  type: TransactionChannelType.Chat,
};

const initialState: TransactionDetailState = {
  isLoading: false,
  activeTransaction: newTransaction,
  notes: null,
  activeTabIndex: TRANSACTION_TABS_OFFSET.SETUP,
  landlordEntitiesOptions: [],
  tenantEntitiesOptions: [],
  propertiesList: [],
  policiesList: [],
  policiesDropdownOptions: [],
  policyDocs: [],
  transactionPremises: [],
  showParametersForm: false,
  percentageModalStatus: false,
  uploadPrepMultiple: null,
  transactionProvisionDropdown: [],
  modificationNotes: null,
  activeTransactionOldVersion: newTransaction,
  activeContractViewTabOffset: TRANSACTION_CONTRACT_VIEW_TABS_OFFSET.ALERTS,
  activeAmendment: newAmendment,
  transactionChannels: [],
  transactionMessages: [],
  automatedTransactionIdentifier: null,
  activeTransactionChannel: newTransactionChannel,
  discussionMode: DiscussionMode.List,
  createDiscussionModalStatus: false,
  isSearchingTransactionChannels: false,
  activeTransactionProvisions: [],
  activeTransactionContents: [],
  activeTransactionAnswers: [],
  activeTransactionAmendments: [],
  activeGroupIds: [],
  activeMessageId: null,
  approvalView: ApprovalView.Submitter,
  transactionApprovalAnswers: [],
  transactionParameterApprovals: [],
  transactionDiscussionApprovals: [],
  transactionProvisionApprovals: [],
  transactionAmendmentApprovals: [],
  uploadInProgress: [],
};

const mapTransactionData = (transaction: TransactionProps, activeTransaction: TransactionProps) => {
  const {
    id,
    owner,
    tenant,
    policy,
    documentTypeIds,
    description,
    identifier,
    historyGeneral,
    historyDocuments,
    alerts,
    translationInfo,
    regulatories,
    documents,
  } = transaction;

  activeTransaction.id = id;
  activeTransaction.ownerId = owner.id;
  activeTransaction.tenantId = tenant?.id;
  activeTransaction.policyId = policy?.id;
  activeTransaction.documentTypeIds = documentTypeIds;
  activeTransaction.description = description;
  activeTransaction.identifier = identifier;
  activeTransaction.historyGeneral = historyGeneral;
  activeTransaction.historyDocuments = historyDocuments;
  activeTransaction.alerts = alerts;
  activeTransaction.translationInfo = translationInfo;
  activeTransaction.regulatories = regulatories;
  activeTransaction.documents = documents;

  return activeTransaction;
};

const mapTransactionPremises = (premises: PremiseProps[], propertiesList: PropertyClone[]) => {
  return premises.map(premise => {
    // Get property
    let property: PropertyClone = propertiesList.find(
      (property: PropertyClone) => property.id === premise.property.id,
    ) as PropertyClone;

    interface AllSpacesInCurrentFloor {
      id: string;
    }

    // Using the floor IDs - found in fetchedContractData.premises[index].floorsStatus, map to get more useful info
    const floorIDsArr = premise.floorsStatus.map(floorStatus => {
      const checkFloor: Floor | undefined = property.floors.find((floor: Floor) => floor.id === floorStatus.floorId);

      let allSpacesInCurrentFloor: AllSpacesInCurrentFloor[] = [];
      if (checkFloor) {
        ({ spaces: allSpacesInCurrentFloor } = checkFloor);
      }
      const spacesInCurrentFloor = [];
      for (let y = 0; y < allSpacesInCurrentFloor.length; y++) {
        const spaceInCurrentFloor = allSpacesInCurrentFloor[y].id;
        for (let x = 0; x < premise.spaceIds.length; x++) {
          const spaceSelectedPreviously = premise.spaceIds[x];

          if (spaceInCurrentFloor === spaceSelectedPreviously) {
            spacesInCurrentFloor.push(spaceInCurrentFloor);
            break;
          }
        }
      }

      return {
        id: floorStatus.floorId,
        floorPortion: floorStatus.whole ? FLOOR_PORTION_OPTIONS.WHOLE : FLOOR_PORTION_OPTIONS.PART,
        spaceIds: spacesInCurrentFloor,
      };
    });

    return {
      floorIds: floorIDsArr, //TODO floorIds is a really bad name.
      property: property,
    };
  });
};

const getApproval = (type: ChangesBoxType, state: WritableDraft<TransactionDetailState>) => {
  switch (type) {
    case ChangesBoxType.Discussion:
      return state.transactionDiscussionApprovals;
    case ChangesBoxType.Amendment:
      return state.transactionAmendmentApprovals;
    case ChangesBoxType.Parameter:
      return state.transactionParameterApprovals;
    case ChangesBoxType.Provision:
      return state.transactionProvisionApprovals;
  }
};

const transactionDetailSlice = createSlice({
  name: 'transactionDetail',
  initialState,
  reducers: {
    updateActiveAmendment: (state, action) => {
      const { key, value } = action.payload;
      const temp = { ...state.activeAmendment };
      // @ts-ignore
      temp[key] = value;
      state.activeAmendment = temp;
    },
    updateTransactionContractViewsTab: (state, action) => {
      state.activeContractViewTabOffset = action.payload.tab;
    },
    updateTransactionViewDetailsTab: (state, action) => {
      state.activeTabIndex = action.payload.tab;
    },
    updateActiveTransaction: (state, action) => {
      const { key, value } = action.payload;
      if (key === 'policyId') {
        let policy = {} as any;
        policy = state.policiesList.find((policy: PolicyListingClone) => policy.id === value);
        state.activeTransaction = {
          ...state.activeTransaction,
          regulatories: policy.regulatories,
        };
        state.policyDocs = policy.documentTypeIds;
      }

      const propertyId =
        state.activeTransaction.premises.length !== 0 ? state.activeTransaction.premises[0].property?.id : null;
      if (propertyId && key === 'tenantId') {
        const identifier = state.automatedTransactionIdentifier;
        state.activeTransaction.identifier = identifier;
      }

      state.activeTransaction = { ...state.activeTransaction, [key]: value };
    },
    createNewTransaction: state => {
      state.activeTransaction = newTransaction as any;
      state.activeTransactionOldVersion = newTransaction as any;
      state.policyDocs = [];
      state.activeTransactionProvisions = [];
      state.transactionPremises = [];
      state.transactionChannels = [];
      state.activeTabIndex = TRANSACTION_TABS_OFFSET.SETUP;
      state.automatedTransactionIdentifier = null;
      state.transactionApprovalAnswers = [];
    },
    updateTransactionPremises: (state, action) => {
      if (action.payload) {
        const { premises } = action.payload;
        state.activeTransaction.premises = premises;

        // redefinition of the identifier in case we change the premises
        const tenantId = state.activeTransaction.tenantId;
        const propertyId = premises.length !== 0 ? premises[0].property?.id : null;
        if (propertyId && tenantId !== null) {
          if (state.activeTransaction.identifier !== null && state.automatedTransactionIdentifier === null) {
            const afterLastDash = state.activeTransaction.identifier.split('-');
            const automatedTransactionIdentifier = afterLastDash[afterLastDash.length - 1];
            state.automatedTransactionIdentifier = automatedTransactionIdentifier;
          }
          state.activeTransaction.identifier = state.automatedTransactionIdentifier;
        }
      }
    },
    updateTransactionParamTab: (state, action) => {
      state.showParametersForm = action.payload.status;
    },
    updateTransactionLatestAnswers: (state, action) => {
      const { paramRef } = action.payload.answer;

      // Check the temporary answers of param ref
      const temporaryParamRefAnswers = state.activeTransactionAnswers.filter(
        obj =>
          obj.paramRef.parameterId === paramRef.parameterId &&
          obj.paramRef.tableId === paramRef.tableId &&
          obj.paramRef.index === paramRef.index &&
          obj.blockRef !== undefined,
      );

      let newAnswer = {
        ...action.payload.answer,
        approvalState: ValidApprovalStates.Pending,
        note: null,
        changedValue: null, // changedValue might be deleted in the future
        transactionMessageId: state.activeMessageId,
      };

      newAnswer.transactionId = state.activeTransaction.id;

      if (paramRef.tableId) {
        action.payload.answer.blockRef = `${paramRef.parameterId}-${paramRef.index}`;
      }

      // If no temporary answers then add new answer
      if (temporaryParamRefAnswers.length === 0) {
        state.activeTransactionAnswers = [...state.activeTransactionAnswers, newAnswer];
      }
      // If temporary answer is already present update that answer
      else {
        const index = current(state.activeTransactionAnswers).findIndex(
          obj =>
            obj.paramRef.parameterId === paramRef.parameterId &&
            obj.paramRef.tableId === paramRef.tableId &&
            obj.paramRef.index === paramRef.index &&
            obj.blockRef !== undefined,
        );
        state.activeTransactionAnswers[index] = newAnswer;
      }
    },
    addTransactionAnswerOfTypeImageFiles: (state, action) => {
      const {
        files: [{ url, name, file, local }],
        index,
        answerType,
        paramRef,
        userId,
      } = action.payload;

      const newAnswer = {
        index,
        paramRef,
        userId,
        answerType,
        dateOfAnswer: getCurrentDateTimeISO(),
        answer: {
          url,
          title: name,
          file,
          local,
        },
      };

      const answerIndex: number = state.activeTransactionAnswers.findIndex(obj => obj.blockRef === index);

      if (answerIndex === -1) {
        state.activeTransactionAnswers = [...state.activeTransactionAnswers, newAnswer];
      } else {
        state.activeTransactionAnswers[answerIndex] = newAnswer;
      }
    },
    deleteTransactionAnswerOfTypeImageFiles: (state, action) => {
      const { index, answerType, paramRef, userId } = action.payload;
      const newAnswer = {
        index,
        paramRef,
        userId,
        answerType,
        dateOfAnswer: getCurrentDateTimeISO(),
        answer: {},
      };

      const answerIndex: number = state.activeTransactionAnswers.findIndex(obj => obj.blockRef === index);

      if (answerIndex === -1) {
        state.activeTransactionAnswers = [...state.activeTransactionAnswers, newAnswer];
      } else {
        state.activeTransactionAnswers[answerIndex] = newAnswer;
      }
    },
    updateIterationValueTransaction: (state, action) => {
      const { parentId, answerType } = action.payload;
      state.activeTransactionAnswers = updateIterationHelper(
        state.activeTransactionAnswers,
        answerType,
        parentId,
        CounterOptions.Increment,
      ) as AnswerProps[];
    },
    deleteIterationAnswersTransaction: (state, action) => {
      const { tabIndex, tableData, parentId, answerType, checkCounter } = action.payload;
      const { id, columns } = tableData;

      if (checkCounter === null) {
        state.activeTransactionAnswers = updateIterationHelper(
          state.activeTransactionAnswers,
          answerType,
          parentId,
          CounterOptions.Decrement,
        ) as AnswerProps[];
      }
      for (let i = 0; i < columns.length; i++) {
        state.activeTransactionAnswers = state.activeTransactionAnswers.filter((obj: AnswerProps) => {
          if (checkIfNotDefined(obj.paramRef.tableId)) {
            return true;
          } else {
            return !(
              obj.paramRef.parameterId === columns[i].parameter.id &&
              obj.paramRef.tableId === id &&
              obj.paramRef.index === tabIndex
            );
          }
        });
      }

      for (let i = 0; i < columns.length; i++) {
        for (let j = 0; j < state.activeTransactionAnswers.length; j++) {
          let answer = state.activeTransactionAnswers[j] as AnswerProps;
          if (answer.paramRef.parameterId === columns[i].parameter.id && answer.paramRef.tableId === id) {
            if (tabIndex < answer.paramRef.index) {
              answer.paramRef.index = (answer.paramRef.index as number) - 1;
            }
          }
        }
      }
    },
    updatePercentageModalStatus: (state, action) => {
      state.percentageModalStatus = action.payload;
    },
    addToDocHistory: (state, action) => {
      const { userId, tenantId, pendingChanges, note, documents, doc, signed } = action.payload;

      for (let i = 0; i < documents.length; i++) {
        const index = state.activeTransaction.historyDocuments.findIndex(
          (historyDocs: HistoryDocumentsProps) => historyDocs.documentTypeId === documents[i],
        );

        let newApprovalRequest: DocHistoryProps = {
          userId,
          tenantId,
          pendingChanges,
          note,
          date: getCurrentDateTimeISO(),
          document: doc ? doc : null,
          status: signed ? ValidDocumentStatus.Signed : ValidDocumentStatus.SentToTenant,
        };

        if (index === -1) {
          newApprovalRequest.version = 1;
          const historyDocument = {
            documentTypeId: documents[i],
            history: [newApprovalRequest],
          };
          state.activeTransaction.historyDocuments.push(historyDocument);
        } else {
          const { history } = state.activeTransaction.historyDocuments[index];
          newApprovalRequest.version = Number(history[history.length - 1].version) + 1;
          state.activeTransaction.historyDocuments[index].history.push(newApprovalRequest);
        }
      }
    },
    resetActiveAmendment: state => {
      state.activeAmendment = newAmendment;
    },
    initWorkflow: (state, action) => {
      const { userId } = action.payload;
      let historyGeneral = [...state.activeTransaction.historyGeneral];

      const generalObj = {
        status: TransactionHistoryGeneralStatus.Setup,
        date: getCurrentDateTimeISO(),
        notes: null,
        userId,
        documents: [],
      };

      if (state.activeTransaction.historyGeneral.length === 0) {
        const opened = {
          status: TransactionHistoryGeneralStatus.Opened,
          date: getCurrentDateTimeISO(),
          notes: null,
          userId,
          documents: [],
        };
        historyGeneral.push(opened);
      }

      historyGeneral.push(generalObj);

      state.activeTransaction.historyGeneral = historyGeneral;
    },
    abortTransaction: (state, action) => {
      const { userId } = action.payload;
      const obj = {
        status: TransactionHistoryGeneralStatus.Aborted,
        date: getCurrentDateTimeISO(),
        notes: state.notes,
        userId,
        documents: [],
      };
      state.activeTransaction.historyGeneral = [...state.activeTransaction.historyGeneral, obj];
    },
    addClosed: (state, action) => {
      state.percentageModalStatus = true;
      const { userId } = action.payload;
      const obj = {
        status: TransactionHistoryGeneralStatus.Closed,
        date: getCurrentDateTimeISO(),
        notes: null,
        userId,
        documents: [],
      };
      state.activeTransaction.historyGeneral = [...state.activeTransaction.historyGeneral, obj];
    },
    onReinstate: (state, action) => {
      const { userId } = action.payload;
      const obj = {
        status: TransactionHistoryGeneralStatus.Reinstated,
        date: getCurrentDateTimeISO(),
        notes: state.notes,
        userId,
        documents: [],
      };
      state.activeTransaction.historyGeneral = [...state.activeTransaction.historyGeneral, obj];
    },
    updateNotes: (state, action) => {
      state.notes = action.payload;
    },
    resetUploadPrep: (state, action) => {
      const { documentTypeId } = action.payload;
      if (state.uploadPrepMultiple !== null) {
        delete state.uploadPrepMultiple[`sign-${documentTypeId}`];
      }
    },
    resetFileUploader: state => {
      state.uploadPrepMultiple = null;
    },
    deleteTransactionDocs: (state, action) => {
      state.activeTransaction.documents = state.activeTransaction.documents.filter(
        doc => doc.document.id !== action.payload.id,
      );
    },
    addTransactionDocs: (state, action) => {
      const { files, name } = action.payload;
      const newDoc = {
        name,
        document: files[0],
      };
      state.activeTransaction.documents = [...state.activeTransaction.documents, newDoc];
    },
    addPostCompletion: (state, action) => {
      const { userId } = action.payload;
      const obj = {
        status: TransactionHistoryGeneralStatus.PostCompletionPending,
        date: getCurrentDateTimeISO(),
        notes: null,
        userId,
        documents: [],
      };
      state.activeTransaction.historyGeneral = [...state.activeTransaction.historyGeneral, obj];
    },
    setTransactionChannel: (state, action) => {
      const { nodeId, documentTypeId } = action.payload;
      let clonedObject = {
        ...newTransactionChannel,
      } as ActiveTransactionChannel;
      state.activeTransactionChannel = {
        ...clonedObject,
        nodeId,
        documentTypeId,
      };
    },
    resetTransactionChannel: state => {
      let clonedObject = {
        ...newTransactionChannel,
      } as ActiveTransactionChannel;
      state.activeTransactionChannel = clonedObject;
    },
    updateChannel: (state, action) => {
      const { key, value } = action.payload;
      // @ts-ignore
      state.activeTransactionChannel[key] = value;
    },
    toggleDiscussionStatus: state => {
      state.activeTransactionChannel.status =
        state.activeTransactionChannel.status === TransactionChannelStatus.Open
          ? TransactionChannelStatus.Resolved
          : TransactionChannelStatus.Open;
    },
    setDiscussionMode: (state, action) => {
      if (action.payload === DiscussionMode.List) {
        state.activeTransactionChannel = newTransactionChannel as ActiveTransactionChannel;
      }
      state.discussionMode = action.payload;
    },
    resetMessages: state => {
      state.transactionMessages = [];
    },
    resetTransactionChannels: state => {
      state.transactionChannels = [];
    },
    updateDiscussionModalStatus: (state, action) => {
      state.createDiscussionModalStatus = action.payload;
    },
    updateActiveGroupIds: (state, action) => {
      state.activeGroupIds = action.payload.value;
    },
    // if blockRef is defined, this means a new answer is defined. so we should keep only those where it is undefined.
    resetTransactionParameterGroupAnswers: state => {
      const oldAnswers = state.activeTransactionAnswers.filter((answer: AnswerProps) => answer.blockRef === undefined);
      state.activeTransactionAnswers = oldAnswers;
    },
    setActiveMessage: (state, action) => {
      state.activeMessageId = action.payload.messageId;
    },
    toggleApprovalView: state => {
      state.approvalView =
        state.approvalView === ApprovalView.Submitter ? ApprovalView.Reviewer : ApprovalView.Submitter;
    },
    updateApprovalAnswer: (state, action) => {
      const { answerId, key, value } = action.payload;
      const index = state.transactionApprovalAnswers.findIndex((obj: TransactionApprovalAnswer) => obj.id === answerId);
      if (key === 'draftMessage') {
        // @ts-ignore
        state.transactionApprovalAnswers[index].transactionDiscussionApproval.draftMessage = value;
      } else {
        // @ts-ignore
        state.transactionApprovalAnswers[index][key] = value;
      }
    },
    setUploadInProgress: (state, action) => {
      state.uploadInProgress = [...state.uploadInProgress, action.payload];
    },
  },
  extraReducers: builder => {
    const handleGetTransactionsPending = (state: TransactionDetailState) => {
      state.isLoading = true;
    };

    const handleGetTransactionsFulfilled = (apiName: string) => (state: TransactionDetailState, action: any) => {
      const data = action.payload.data[apiName];
      // @ts-ignore
      const transactionData = mapTransactionData(data, state.activeTransaction);
      state.activeTransaction = transactionData;
      state.activeTransactionAnswers = [...data.answers, ...state.transactionApprovalAnswers].filter(
        (answer: TransactionApprovalAnswer) => answer.paramRef !== null,
      );

      state.activeTransactionContents = data.contents;

      if (data.amendments) state.activeTransactionAmendments = data.amendments;

      state.activeTransactionOldVersion = transactionData;
      state.transactionPremises = data.premises;
      state.policyDocs = data.policy !== null ? data.policy?.documentTypeIds : [];
      state.uploadPrepMultiple = null;
      state.modificationNotes = null;
      state.automatedTransactionIdentifier = null;
      state.transactionChannels = [];
      state.transactionMessages = [];
      state.discussionMode = DiscussionMode.List;
      state.uploadInProgress = [];
      state.isLoading = false;
    };

    const handleGetTransactionsRejected = (state: TransactionDetailState, action: { error: unknown }) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('An error occured trying to fetch transaction data');
    };

    //getLandlordTransaction
    builder.addCase(getLandlordTransaction.pending, handleGetTransactionsPending);
    builder.addCase(getLandlordTransaction.fulfilled, handleGetTransactionsFulfilled('getLandlordTransaction'));
    builder.addCase(getLandlordTransaction.rejected, handleGetTransactionsRejected);

    //getTenantTransaction
    builder.addCase(getTenantTransaction.pending, handleGetTransactionsPending);
    builder.addCase(getTenantTransaction.fulfilled, handleGetTransactionsFulfilled('getTenantTransaction'));
    builder.addCase(getTenantTransaction.rejected, handleGetTransactionsRejected);

    // getTransactionContentsOnly
    builder.addCase(getTransactionContentsOnly.fulfilled, (state, action) => {
      const contents = action.payload.data.getTransaction.contents;
      state.activeTransactionContents = contents;
    });
    builder.addCase(getTransactionContentsOnly.rejected, (_state, action) => {
      console.error(action.error);
      toast.error('An error occured while fetching transaction contents');
    });

    //Create Transaction
    builder.addCase(createTransaction.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createTransaction.fulfilled, state => {
      state.isLoading = false;
      toast.success('Transaction created Successfully');
    });
    builder.addCase(createTransaction.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('An error occurred when trying to add Transaction');
    });

    //Update Transaction
    builder.addCase(updateTransaction.pending, (state, action) => {
      if (action.meta.arg.approval) {
        state.isLoading = true;
      } else {
        state.isLoading = false;
      }
    });
    builder.addCase(updateTransaction.fulfilled, (state, action) => {
      const { answers, historyGeneral, historyDocuments, contents, documents } = action.payload.data.updateTransaction;
      state.activeTransactionAnswers = answers.filter((answer: TransactionApprovalAnswer) => answer.paramRef !== null);
      state.activeTransaction.historyGeneral = historyGeneral;
      state.activeTransaction.historyDocuments = historyDocuments;
      state.activeTransactionContents = contents;
      state.activeTransaction.documents = documents;
      state.isLoading = false;
      state.notes = null;
      state.percentageModalStatus = false;
      state.uploadPrepMultiple = null;
      state.modificationNotes = null;
      state.activeTransactionOldVersion = state.activeTransaction;
      state.uploadInProgress = [];
      toast.success('Transaction updated Successfully');
    });
    builder.addCase(updateTransaction.rejected, (state, action) => {
      state.isLoading = false;
      state.percentageModalStatus = false;
      console.error(action.error);
      toast.error('An error occurred when trying to update Transaction');
    });

    // Create Transaction Answers
    builder.addCase(createTransactionAnswers.pending, state => {
      state.isLoading = false;
    });
    builder.addCase(createTransactionAnswers.fulfilled, (state, action) => {
      const newAnswers = action.payload.data.createTransactionAnswers;
      const oldAnswers = state.activeTransactionAnswers.filter((answer: AnswerProps) => answer.blockRef === undefined);
      state.activeTransactionAnswers = [...oldAnswers, ...newAnswers];
      state.activeMessageId = null;
      state.isLoading = false;
    });
    builder.addCase(createTransactionAnswers.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('An error occurred when trying to update Transaction Answers');
    });

    // Initialize Transaction
    builder.addCase(updateTransactionWithInitalization.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateTransactionWithInitalization.fulfilled, (state, action) => {
      const { answers, historyGeneral, historyDocuments, contents } =
        action.payload.data.updateTransactionWithInitialization;
      state.activeTransactionAnswers = answers;
      state.activeTransaction.historyGeneral = historyGeneral;
      state.activeTransaction.historyDocuments = historyDocuments;
      state.activeTransactionContents = contents;
      state.isLoading = false;
      state.notes = null;
      state.percentageModalStatus = false;
      state.uploadPrepMultiple = null;
      state.modificationNotes = null;
      state.activeTransactionOldVersion = state.activeTransaction;
      toast.success('Transaction initialized successfully');
    });
    builder.addCase(updateTransactionWithInitalization.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('An error occurred when initializing Transaction');
    });

    // deleteContractOfTransaction
    builder.addCase(deleteContractOfTransaction.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteContractOfTransaction.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteContractOfTransaction.rejected, (state, action) => {
      console.error(action.error);
      state.isLoading = false;
    });

    // listLandlordAndTenantEntities
    builder.addCase(listLandlordAndTenantEntities.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(listLandlordAndTenantEntities.fulfilled, (state, action) => {
      const landlordEdges = action.payload?.data?.landlord?.edges ?? [];
      const landlordsList: LandlordsEntitiesProps[] = landlordEdges.map(
        (element: { node: LandlordsEntitiesProps }) => element.node,
      ) as LandlordsEntitiesProps[];
      const tenantEdges = action.payload?.data?.tenant?.edges ?? [];
      const tenantsList: TenantEntityWithJoin[] = tenantEdges.map(
        (element: { node: TenantEntityWithJoin }) => element.node,
      ) as TenantEntityWithJoin[];

      state.landlordEntitiesOptions = landlordsList.map((item: LandlordsEntitiesProps, index: number) => {
        const result: DROPDOWN_OPTION = {
          key: index,
          text: item.name,
          value: item.id,
        };
        return result;
      });

      state.tenantEntitiesOptions = tenantsList.map((item: TenantEntityWithJoin, index: number) => {
        const result: DROPDOWN_OPTION = {
          key: index,
          text: item.name,
          value: item.id,
        };
        return result;
      });

      state.isLoading = false;
    });
    builder.addCase(listLandlordAndTenantEntities.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('listLandlordAndTenantEntities API call rejected');
    });

    // listPremises
    builder.addCase(listPremises.fulfilled, (state, action) => {
      const propertiesEdges = action.payload?.data?.listProperties?.edges ?? [];
      if (propertiesEdges.length) {
        const propertiesList: PropertyClone[] = propertiesEdges.map(
          (element: { node: PropertyClone }) => element.node,
        ) as PropertyClone[];
        state.propertiesList = propertiesList;

        if (!action.meta.arg.ownerId) {
          const premises = mapTransactionPremises(state.transactionPremises, state.propertiesList);
          state.activeTransaction.premises = premises;
          state.activeTransactionOldVersion.premises = premises;
        }
      }
    });
    builder.addCase(listPremises.rejected, (_state, action) => {
      console.error(action.error);
      toast.error('listPremises API call rejected');
    });

    // Listing policies
    builder.addCase(listPolicies.fulfilled, (state, action) => {
      const edges = action.payload?.listPolicies?.edges ?? [];
      const policiesList: PolicyListingClone[] = edges.map((element: { node: PolicyListingClone }) => element.node);
      const policiesOptions = policiesList.map((element: PolicyListingClone, index: number) => {
        const result: DROPDOWN_OPTION = {
          key: index,
          text: element.name,
          value: element.id,
        };
        return result;
      });
      state.policiesList = policiesList;
      state.policiesDropdownOptions = policiesOptions;
      state.isLoading = false;
    });
    builder.addCase(listPolicies.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('An error occurred when trying to load the Policies: listPolicies');
    });

    //prepTransactionAnswerOfImageTypeForUpload
    builder.addCase(prepTransactionAnswerOfImageTypeForUpload.fulfilled, (state, action) => {
      const { cdnUrl, uploadUrl } = action.payload.data.prepPolicyImageAnswer;

      const answerIndex = state.activeTransactionAnswers.findIndex(obj => obj.blockRef === action.meta.arg.index);

      const newAnswer = {
        ...state.activeTransactionAnswers[answerIndex].answer,
        cdnUrl,
        uploadUrl,
      };

      state.activeTransactionAnswers[answerIndex].answer = newAnswer;
    });
    builder.addCase(prepTransactionAnswerOfImageTypeForUpload.rejected, (_state, action) => {
      console.error(action.error);
      toast.error('prepTransactionAnswerOfImageTypeForUpload API called rejected');
    });

    // prepTransactionDocForUpload
    builder.addCase(prepTransactionDocForUpload.fulfilled, (state, action) => {
      const { files, docId } = action.meta.arg;
      const file = files[0];
      if (docId) {
        if (state.uploadPrepMultiple) {
          state.uploadPrepMultiple[docId] = {
            uploadPrep: action.payload.data.prepTransactionDocument,
            fileDetails: file,
          };
        } else {
          state.uploadPrepMultiple = {
            [docId]: {
              uploadPrep: action.payload.data.prepTransactionDocument,
              fileDetails: file,
            },
          };
        }
      }
      state.isLoading = false;
    });

    builder.addCase(prepTransactionDocForUpload.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('prepTransactionDocForUpload API call rejected');
    });

    //translateToContract
    builder.addCase(translateToContract.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(translateToContract.fulfilled, (state, action) => {
      const data = action.payload?.data?.translateToContract;
      const alerts = data?.alerts ?? [];
      state.activeTransaction.alerts = alerts;
      state.activeTransaction.translationInfo = data?.translationInfo;
      state.isLoading = false;

      // the Toast alert is raised only if alerts of type Error or Warnings are generated during translation.
      const alertToast: boolean = !!alerts.find((alert: TransactionAlertClone) =>
        [TransactionAlertType.Error, TransactionAlertType.Warning].includes(alert.type),
      );
      if (action.meta.arg.toast) {
        if (alertToast) {
          toast.warning(
            'Transaction answers updated successfully. Note that some alerts have been raised after analysis.',
            {
              className: 'translate-toast',
            },
          );
        } else {
          toast.success('Transaction answers updated successfully.');
        }
      }
    });
    builder.addCase(translateToContract.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('translateToContract API call rejected');
    });

    //Generate Modification Notes
    builder.addCase(generateModificationNotes.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(generateModificationNotes.fulfilled, (state, action) => {
      const generatedModificationNotes = action.payload.data.generateModificationNotes;
      state.modificationNotes = generatedModificationNotes.modificationNotes;
      generatedModificationNotes.parameterIds.map((parameterId: string) =>
        toast.error('Parameter ' + parameterId + ' not found'),
      );
      state.isLoading = false;
    });
    builder.addCase(generateModificationNotes.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('An error occurred when trying to generate modification notes');
    });

    // Get Automated Transaction Identifier
    builder.addCase(getAutomatedTransactionIdentifier.fulfilled, (state, action) => {
      state.automatedTransactionIdentifier =
        action.payload.data.getAutomatedTransactionIdentifier.automatedTransactionIdentifier;
    });
    builder.addCase(getAutomatedTransactionIdentifier.rejected, (_state, action) => {
      console.error(action.error);
      toast.error('An error occurred when trying to get automated transaction identifier');
    });

    // Listing transaction channels
    builder.addCase(listTransactionChannels.fulfilled, (state, action) => {
      state.transactionChannels = action.payload.data.listTransactionChannels;
      state.isLoading = false;
    });
    builder.addCase(listTransactionChannels.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('An error occurred when trying to load the Transaction Channels');
    });

    // Get Transaction Channel
    builder.addCase(getTransactionChannel.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.activeTransactionChannel = data.getTransactionChannel;
      state.isLoading = false;
    });
    builder.addCase(getTransactionChannel.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('getTransactionChannel API call rejected');
    });

    // Create Transaction Channel
    builder.addCase(createTransactionChannel.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createTransactionChannel.fulfilled, (state, action) => {
      const { id, documentTypeId, insertedAt } = action.payload.data.createTransactionChannel;
      state.activeTransactionChannel.id = id;
      state.activeTransactionChannel.documentTypeId = documentTypeId;
      state.activeTransactionChannel.insertedAt = insertedAt;
      state.isLoading = false;
      toast.success('Transaction channel created Successfully');
    });
    builder.addCase(createTransactionChannel.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('An error occurred when trying to add Transaction channel');
    });

    //Update Transaction Channel
    builder.addCase(updateTransactionChannel.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateTransactionChannel.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.activeTransactionChannel = data.updateTransactionChannel;
      state.isLoading = false;
      toast.success(`Transaction Channel Updated Successfully`);
    });
    builder.addCase(updateTransactionChannel.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('updateTransactionChannel API call rejected');
    });

    // Get Transaction Alerts
    builder.addCase(listTransactionAlerts.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(listTransactionAlerts.fulfilled, (state, action) => {
      state.activeTransaction.alerts = action.payload.data.getTransaction.alerts;
      state.isLoading = false;
    });
    builder.addCase(listTransactionAlerts.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('An error occurred when trying to get transaction alerts');
    });

    // GetAmendments
    builder.addCase(listAmendments.fulfilled, (state, action) => {
      const data = action.payload.data;
      const amendments: Amendment[] = data.listAmendments;
      state.activeTransactionAmendments = amendments;
    });
    builder.addCase(listAmendments.rejected, (_state, action) => {
      console.error(action.error);
      toast.error('An error occurred while trying to get amendments');
    });

    // Get Amendment
    builder.addCase(getAmendment.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getAmendment.fulfilled, (state, action) => {
      const data = action.payload.data;

      const amendment: Amendment = data.getAmendment;

      state.activeAmendment = {
        id: amendment.id,
        name: amendment?.name,
        index: amendment.index,
        content: amendment.content,
        documentTypeId: amendment.documentType.id!,
        categoryId: amendment.category.id!,
      };

      state.isLoading = false;
    });
    builder.addCase(getAmendment.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('An error occurred while trying to get amendment');
    });

    // Create Amendment
    builder.addCase(createAmendment.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createAmendment.fulfilled, (state, action) => {
      const amendment = action.payload.data.createAmendment;
      state.activeAmendment = amendment;
      state.activeTransactionAmendments?.push(amendment);
      state.isLoading = false;
      toast.success('Amendment created successfully');
    });
    builder.addCase(createAmendment.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('An error occurred while trying to create amendment');
    });

    // createAmendmentFromTransactionDiscussion
    builder.addCase(createAmendmentFromTransactionDiscussion.fulfilled, (state, action) => {
      const amendment = action.payload.data.createAmendmentFromTransactionDiscussion;
      state.activeTransactionAmendments?.push(amendment);
      state.isLoading = false;
      toast.success('Amendment created successfully');
    });
    builder.addCase(createAmendmentFromTransactionDiscussion.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('An error occurred while trying to create amendment');
    });

    // Update Amendment
    builder.addCase(updateAmendment.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateAmendment.fulfilled, state => {
      state.isLoading = false;
      toast.success('Amendment updated successfully');
    });
    builder.addCase(updateAmendment.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('An error occurred while trying to update amendment');
    });

    // Delete Amendment
    builder.addCase(deleteAmendment.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteAmendment.fulfilled, (state, action) => {
      const deletedAmendmentId = action.payload.data.deleteAmendment.id;
      const amendments = state.activeTransactionAmendments;
      state.activeTransactionAmendments = amendments?.filter(({ id }) => id !== deletedAmendmentId) as Amendment[];
      state.isLoading = false;
      toast.success('Amendment deleted successfully');
    });
    builder.addCase(deleteAmendment.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('An error occurred while trying to delete amendment');
    });

    // Listing Transaction messages
    builder.addCase(listTransactionMessages.fulfilled, (state, action) => {
      state.transactionMessages = action.payload.data.listTransactionMessages;
      state.isLoading = false;
    });
    builder.addCase(listTransactionMessages.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('An error occurred when trying to loading the Transactions Messages');
    });

    // Create Transaction Message
    builder.addCase(createTransactionMessage.pending, (state, action) => {
      state.isLoading = action.meta.arg.loading || false;
    });
    builder.addCase(createTransactionMessage.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(createTransactionMessage.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('An error occurred when trying to add Transaction message');
    });

    // Update Transaction Message
    builder.addCase(updateTransactionMessage.rejected, (_state, action) => {
      console.error(action.error);
      toast.error('An error occurred when trying to Update Transaction message');
    });

    // prepTransactionDocsForUpload
    builder.addCase(prepTransactionDocsForUpload.fulfilled, (state, action) => {
      const documentsCopy = [...state.activeTransaction.documents];
      Object.keys(action.payload.data).map(key => {
        const id = key.replace('prepTransactionDoc', '');
        const index = documentsCopy.findIndex(doc => doc.document.id === id);

        const updatedDocument = {
          name: documentsCopy[index].name,
          document: {
            ...documentsCopy[index].document,
            ...action.payload.data[key],
          },
        };

        documentsCopy.splice(index, 1, {
          ...documentsCopy[index],
          ...updatedDocument,
        });
      });

      state.activeTransaction.documents = documentsCopy;
    });
    builder.addCase(prepTransactionDocsForUpload.rejected, (_state, action) => {
      console.error(action.error);
      toast.error('updateTransactionChannel API call rejected');
    });

    // Update Transaction Provision
    builder.addCase(updateTransactionProvision.fulfilled, (state, action) => {
      const provisionId = action.meta.arg.id;
      const selected = action.meta.arg.transactionProvision.selected;
      const index = state.activeTransactionProvisions.findIndex(
        (provision: TransactionProvisionTemp) => provision.id === provisionId,
      );
      const { provision, answer } = action.payload.data.updateTransactionProvision;
      state.activeTransactionProvisions[index] = provision;
      if (answer !== null) {
        state.activeTransactionAnswers = [...state.activeTransactionAnswers, answer];
      }
      const message = selected ? 'added' : 'removed';
      toast.success(`Transaction provision ${message} Successfully`);
    });
    builder.addCase(updateTransactionProvision.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error(`An error occurred when trying to update transaction provision`);
    });

    // Get transaction provisions
    builder.addCase(listTransactionProvisions.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(listTransactionProvisions.fulfilled, (state, action) => {
      state.activeTransactionProvisions = action.payload.data.listTransactionProvisions;
      // Only have mandatory or selected provisions
      const activeTransactionProvisions: TransactionProvisionTemp[] = state.activeTransactionProvisions.filter(
        (provision: TransactionProvisionTemp) => provision.selected === true || provision.mandatory === true,
      );
      state.transactionProvisionDropdown = provisionsDropdown(activeTransactionProvisions);
      state.isLoading = false;
    });
    builder.addCase(listTransactionProvisions.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('An error occurred when trying to loading the Provisions');
    });

    // uploadSendToTenantPDF
    builder.addCase(uploadSendToTenantPDF.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(uploadSendToTenantPDF.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(uploadSendToTenantPDF.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('An error occurred when trying to upload approved pdf');
    });

    // getTransactionHistoryDocuments
    builder.addCase(getTransactionHistoryDocuments.fulfilled, (state, action) => {
      const historyDocuments = action.payload.data.getLandlordTransaction.historyDocuments;
      state.activeTransaction.historyDocuments = historyDocuments;
      state.isLoading = false;
      toast.success('Document generated successfully.');
    });
    builder.addCase(getTransactionHistoryDocuments.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('An error occurred when trying to get history documents');
    });

    // listTransactionParameterApprovals
    builder.addCase(listTransactionParameterApprovals.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(listTransactionParameterApprovals.fulfilled, (state, action) => {
      const listTransactionParameterApprovals = action.payload.data.listTransactionParameterApprovals;
      state.transactionParameterApprovals = listTransactionParameterApprovals;
      state.isLoading = false;
    });
    builder.addCase(listTransactionParameterApprovals.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('An error occurred when trying to get transaction parameter approvals');
    });

    // listTransactionDiscussionApprovals
    builder.addCase(listTransactionDiscussionApprovals.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(listTransactionDiscussionApprovals.fulfilled, (state, action) => {
      const listTransactionDiscussionApprovals = action.payload.data.listTransactionDiscussionApprovals;
      state.transactionDiscussionApprovals = listTransactionDiscussionApprovals;
      state.isLoading = false;
    });
    builder.addCase(listTransactionDiscussionApprovals.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('An error occurred when trying to get transaction discussion approvals');
    });

    // listTransactionProvisionApprovals
    builder.addCase(listTransactionProvisionApprovals.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(listTransactionProvisionApprovals.fulfilled, (state, action) => {
      const listTransactionProvisionApprovals = action.payload.data.listTransactionProvisionApprovals;
      state.transactionProvisionApprovals = listTransactionProvisionApprovals;
      state.isLoading = false;
    });
    builder.addCase(listTransactionProvisionApprovals.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('An error occurred when trying to get transaction provision approvals');
    });

    // updateTransactionParameterApproval
    builder.addCase(updateTransactionParameterApproval.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateTransactionParameterApproval.fulfilled, (state, action) => {
      const { approvalId } = action.meta.arg;
      const updatedTransactionParameterApproval = action.payload.data.updateTransactionParameterApproval;
      const approvalIndex = state.transactionParameterApprovals.findIndex(
        (obj: TransactionParameterApproval) => obj.id === approvalId,
      );
      state.transactionParameterApprovals[approvalIndex] = updatedTransactionParameterApproval;
      state.isLoading = false;
      toast.success('Parameter changes approved successfully');
    });
    builder.addCase(updateTransactionParameterApproval.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('An error occurred when transaction parameter approval updated');
    });

    // Create Transaction DiscussionApproval
    builder.addCase(createTransactionDiscussionApproval.pending, (state, _action) => {
      state.isLoading = false;
    });
    builder.addCase(createTransactionDiscussionApproval.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(createTransactionDiscussionApproval.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('An error occurred when trying to add transaction discussion approval');
    });

    // updateTransactionDiscussionApproval
    builder.addCase(updateTransactionDiscussionApproval.fulfilled, (state, action) => {
      const { approvalId } = action.meta.arg;
      const updateTransactionDiscussionApproval = action.payload.data.updateTransactionDiscussionApproval;
      const approvalIndex = state.transactionDiscussionApprovals.findIndex(
        (obj: TransactionDiscussionApproval) => obj.id === approvalId,
      );
      state.transactionDiscussionApprovals[approvalIndex] = updateTransactionDiscussionApproval;
    });
    builder.addCase(updateTransactionDiscussionApproval.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('An error occurred when transaction discussion approval updated');
    });

    // updateTransactionProvisionApproval
    builder.addCase(updateTransactionProvisionApproval.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateTransactionProvisionApproval.fulfilled, (state, action) => {
      const { approvalId } = action.meta.arg;
      const updateTransactionProvisionApproval = action.payload.data.updateTransactionProvisionApproval;
      const approvalIndex = state.transactionProvisionApprovals.findIndex(
        (obj: TransactionProvisionApproval) => obj.id === approvalId,
      );
      state.transactionProvisionApprovals[approvalIndex] = updateTransactionProvisionApproval;
      state.isLoading = false;
      toast.success('Transaction provision approval updated successfully');
    });
    builder.addCase(updateTransactionProvisionApproval.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('An error occurred when transaction provision approval updated');
    });

    // listTransactionApprovalAnswers
    builder.addCase(listTransactionApprovalAnswers.pending, state => {
      state.transactionApprovalAnswers = [];
      state.isLoading = true;
    });
    builder.addCase(listTransactionApprovalAnswers.fulfilled, (state, action) => {
      const listTransactionApprovalAnswers = action.payload.data.listTransactionApprovalAnswers;
      state.transactionApprovalAnswers = listTransactionApprovalAnswers;
      state.activeTransactionAnswers = [...state.activeTransactionAnswers, ...listTransactionApprovalAnswers];

      state.isLoading = false;
    });
    builder.addCase(listTransactionApprovalAnswers.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('An error occurred when trying to list transaction approval answers');
    });

    // Create Transaction Approval Answers
    builder.addCase(createTransactionApprovalAnswers.pending, state => {
      state.isLoading = false;
    });
    builder.addCase(createTransactionApprovalAnswers.fulfilled, (state, action) => {
      const newAnswers = action.payload.data.createTransactionApprovalAnswers;
      const oldAnswers = state.activeTransactionAnswers.filter((answer: AnswerProps) => answer.blockRef === undefined);
      state.activeTransactionAnswers = [...oldAnswers, ...newAnswers];
      state.transactionApprovalAnswers = [...state.transactionApprovalAnswers, ...newAnswers];
      state.activeMessageId = null;
      state.isLoading = false;
    });
    builder.addCase(createTransactionApprovalAnswers.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('An error occurred when trying to create Transaction approval Answers');
    });

    // Update Transaction Approval Answer
    builder.addCase(updateTransactionApprovalAnswer.fulfilled, (state, action) => {
      const { answerId } = action.meta.arg;
      const updateTransactionApprovalAnswer = action.payload?.data.updateTransactionApprovalAnswer;
      const approvalIndex = state.transactionApprovalAnswers.findIndex(
        (obj: TransactionApprovalAnswer) => obj.id === answerId,
      );
      state.transactionApprovalAnswers[approvalIndex] = updateTransactionApprovalAnswer;

      // To be improved later
      const transactionAnswerIndex = state.activeTransactionAnswers.findIndex(
        (obj: AnswerProps) => obj.id === answerId,
      );
      state.activeTransactionAnswers[transactionAnswerIndex] = updateTransactionApprovalAnswer;
      state.isLoading = false;
      toast.success('Answer updated successfully');
    });
    builder.addCase(updateTransactionApprovalAnswer.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('An error occurred when transaction provision approval updated');
    });

    // Update Transaction Approval Answers State
    builder.addCase(updateTransactionApprovalAnswersState.fulfilled, (state, action) => {
      const { approvalAnswerIds, approvalState } = action.meta.arg;
      const approvalAnswers: TransactionApprovalAnswer[] = state.transactionApprovalAnswers.filter(
        (obj: TransactionApprovalAnswer) => approvalAnswerIds.includes(obj.id),
      );
      for (let i = 0; i < approvalAnswers.length; i++) {
        approvalAnswers[i].approvalState = approvalState;
      }

      // To be improved later
      const answers: AnswerProps[] = state.activeTransactionAnswers.filter((obj: AnswerProps) =>
        approvalAnswerIds.includes(obj.id as string),
      );
      for (let i = 0; i < answers.length; i++) {
        answers[i].approvalState = approvalState;
      }

      state.transactionApprovalAnswers = state.transactionApprovalAnswers.filter(
        (answer: TransactionApprovalAnswer) => answer.approvalState !== ValidApprovalStates.Completed,
      );

      state.activeTransactionAnswers = state.activeTransactionAnswers.filter(
        (answer: AnswerProps) => answer.approvalState !== ValidApprovalStates.Completed,
      );

      state.isLoading = false;
      toast.success('Answers updated successfully');
    });
    builder.addCase(updateTransactionApprovalAnswersState.rejected, state => {
      state.isLoading = false;
      toast.error('An error occurred when transaction approval answers state updated');
    });

    // Delete Old Transaction Approval Answers
    builder.addCase(deleteOldTransactionApprovalAnswers.pending, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteOldTransactionApprovalAnswers.fulfilled, (state, action) => {
      const answerIds = action.payload?.answerIds;

      state.transactionApprovalAnswers = state.transactionApprovalAnswers.filter(
        (answer: TransactionApprovalAnswer) => !answerIds?.includes(answer.id as string),
      );

      // To be improved later
      state.activeTransactionAnswers = state.activeTransactionAnswers.filter(
        (answer: AnswerProps) => !answerIds?.includes(answer.id as string),
      );

      state.isLoading = false;
    });
    builder.addCase(deleteOldTransactionApprovalAnswers.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('An error occurred when trying to delete old Transaction approval Answers');
    });

    // Create Transaction Parameter Approval
    builder.addCase(createTransactionParameterApproval.pending, (state, _action) => {
      state.isLoading = false;
    });
    builder.addCase(createTransactionParameterApproval.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(createTransactionParameterApproval.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('An error occurred when trying to add transaction parameter approval');
    });

    // Create Transaction Messages
    builder.addCase(createTransactionMessages.rejected, (_state, action) => {
      console.error(action.error);
      toast.error('An error occurred when trying to create transaction messages');
    });

    //setCrossRefInTransaction
    builder.addCase(setCrossRefInTransaction.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(setCrossRefInTransaction.fulfilled, (state, action) => {
      const data = action.payload.data.setCrossRefInTransaction;
      state.activeTransactionContents = data;
      state.isLoading = false;
    });
    builder.addCase(setCrossRefInTransaction.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error);
      toast.error('setCrossRefInTransaction API request failed');
    });
  },
});

export const {
  updateActiveTransaction,
  createNewTransaction,
  updateTransactionViewDetailsTab,
  updateTransactionContractViewsTab,
  updateTransactionPremises,
  updateTransactionParamTab,
  updateTransactionLatestAnswers,
  addTransactionAnswerOfTypeImageFiles,
  deleteTransactionAnswerOfTypeImageFiles,
  updateIterationValueTransaction,
  deleteIterationAnswersTransaction,
  updatePercentageModalStatus,
  addToDocHistory,
  initWorkflow,
  abortTransaction,
  addClosed,
  updateNotes,
  onReinstate,
  resetUploadPrep,
  resetFileUploader,
  deleteTransactionDocs,
  addTransactionDocs,
  addPostCompletion,
  updateActiveAmendment,
  resetActiveAmendment,
  setTransactionChannel,
  resetTransactionChannel,
  updateChannel,
  toggleDiscussionStatus,
  setDiscussionMode,
  resetMessages,
  resetTransactionChannels,
  updateDiscussionModalStatus,
  updateActiveGroupIds,
  resetTransactionParameterGroupAnswers,
  setActiveMessage,
  toggleApprovalView,
  updateApprovalAnswer,
  setUploadInProgress,
} = transactionDetailSlice.actions;

export default transactionDetailSlice.reducer;
