import { GuidelineClone, ParameterChoiceBase } from 'common/_classes';
import { useCallback, useEffect, useState } from 'react';
import { Accordion, Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { debounce, isEqual, sortBy } from 'lodash';
import { Icon } from '@iconify/react';
import Badge, { BadgeColor } from 'atoms/Badge';
import ConfirmPopup from 'atoms/ConfirmPopup';
import ParameterAnswerSwitch from 'components/ParameterAnswerAccordion/ParameterAnswerSwitch';
import { PREVIEW_INFORMATION_TABS_OFFSET } from 'components/PreviewTab/PreviewEditorSideMenu';
import { ParameterModes } from 'store/miscellaneous/miscellaneousSlice';
import AnswerTypes from 'common/model/AnswerTypes';
import ExecuteContext from 'common/model/ExecuteContext';
import Parameter from 'common/model/Parameter';
import ParameterTypes from 'common/model/ParameterTypes';
import { initAnswerValue } from 'common/api/formatters/types';
import { listContextParametersGroup } from 'common/api/parameters';
import { AnswerProps, deletePolicyParamRefAnswers } from 'common/api/policies';
import { checkIfPoliciesPage } from 'utils/tsHelper';
import { checkNotEmpty } from 'utils/tsValidator';
import {
  checkIfFromDeviation,
  checkPolicyInAnswers,
  getAnswerValue,
  getAnswers,
  getLatestAnswer,
  updateAnswer,
} from 'utils/utils-answer';
import { Icons } from 'utils/utils-icons';
import GuideLineBoxes from '../GuideLineBoxes';
import AnswerHistoryModal from '../ParameterAnswerAccordion/AnswerHistoryModal';
import './ParameterAnswerAccordion.scss';

export enum GuidelineLocationTypes {
  RIGHT = 'RIGHT',
  BOTTOM = 'BOTTOM',
}

interface ParameterAnswerAccordionProps {
  parameter: Parameter;
  index: number;
  checkIfAnswer: boolean;
  tableId: string | null;
  tabIndex: number | null;
  guidelineLocation: GuidelineLocationTypes;
}

// Receives the question and displays it inside the accordion
// changes the header color depending on the legend
const ParameterAnswerAccordion = ({
  parameter,
  index,
  checkIfAnswer,
  tableId,
  tabIndex,
  guidelineLocation,
}: ParameterAnswerAccordionProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [isActive, setIsActive] = useState<boolean>(checkIfAnswer ? false : true);
  const { activePolicy, activePolicyAnswers } = useAppSelector((state: RootState) => state.policyDetail);
  const { activeTransaction, activeTransactionAnswers } = useAppSelector((state: RootState) => state.transactionDetail);
  const { conditional, parameterMode, selectedProvisionFilter } = useAppSelector(
    (state: RootState) => state.miscellaneous,
  );

  const choices = sortBy(parameter.choices, 'index');

  const answers: AnswerProps[] = getAnswers(activePolicyAnswers, activeTransactionAnswers);
  const answerSize: number = answers.length;

  const { parametersCollection, parameterTablesCollection } = useAppSelector((state: RootState) => state.parametersTab);

  const choiceIds: string[] = choices?.map((choice: ParameterChoiceBase) => choice.id);

  let answersList: AnswerProps[] = answers.filter(
    (obj: AnswerProps) =>
      obj.answerType === parameter.answerType &&
      obj.paramRef.parameterId === parameter.id &&
      obj.paramRef.tableId === tableId &&
      obj.paramRef.index === tabIndex,
  );

  const checkIfFromPolicy: boolean = checkPolicyInAnswers(answersList);
  const alternative: boolean = parameter.type === ParameterTypes.AlternativeLanguage;

  const checkIfTransaction: boolean = window.location.pathname.includes('transactions');
  const context: ExecuteContext = checkIfTransaction ? ExecuteContext.Transaction : ExecuteContext.Policy;
  const contextId: string | undefined = checkIfTransaction ? activeTransaction.id : activePolicy.id;

  const { activeTab: activePreviewTab } = useAppSelector((state: RootState) => state.hiddenMenu);

  const checkIfDiscussionTab: boolean = activePreviewTab === PREVIEW_INFORMATION_TABS_OFFSET.DISCUSSION;

  const emptyValue = initAnswerValue(parameter.answerType, choiceIds);
  // Stores the value of questions
  const [value, setValue] = useState<any>(emptyValue);

  const onChange = () => {
    dispatch(
      listContextParametersGroup({
        context,
        contextId,
        provisionId: checkIfDiscussionTab ? null : selectedProvisionFilter,
        conditional: checkIfDiscussionTab ? true : conditional,
        mode: checkIfDiscussionTab ? ParameterModes.Detailed : parameterMode,
        parameterGroupId: parameter.parameterGroup?.id,
      }),
    );
  };

  /**
   * Get the latest answers
   */
  const onLatestAnswerUpdate = (): void => {
    const latestAnswerValue = getLatestAnswer({
      answers,
      answerType: parameter.answerType,
      parameterId: parameter.id,
      tableId,
      tabIndex,
      // @ts-ignore
      emptyValue,
    });
    setValue(latestAnswerValue);
  };

  // Call only after 0.5s of no user input activity
  const onChangeRequest = debounce(() => {
    onChange();
  }, 500);

  const debounceOnChange = useCallback(() => onChangeRequest(), []);

  /**
   * On value change update the answer value
   * @param value
   */
  const onAnswerValueUpdate = (value: any): void => {
    updateAnswer({
      value,
      blockRef: tableId ? `${parameter.id}-${tabIndex}` : index,
      answerType: parameter.answerType,
      answerUnit: parameter.unit,
      parameterId: parameter.id,
      tableId,
      tabIndex,
      dispatch,
    });
    if (
      parameter.answerType === AnswerTypes.Duration &&
      checkNotEmpty(value.years) &&
      checkNotEmpty(value.months) &&
      checkNotEmpty(value.days)
    ) {
      debounceOnChange();
    } else if (parameter.answerType !== AnswerTypes.Duration) {
      debounceOnChange();
    }
    setValue(value);
  };

  useEffect(() => {
    onLatestAnswerUpdate();
  }, [tabIndex]);

  useEffect(() => {
    if (tabIndex === 0) {
      onLatestAnswerUpdate();
    }
  }, [answerSize]);

  useEffect(() => {
    onLatestAnswerUpdate();
  }, [parametersCollection, parameterTablesCollection]);

  const ParameterQuestionContent: JSX.Element = ParameterAnswerSwitch(
    value,
    parameter,
    index,
    tableId,
    tabIndex,
    onAnswerValueUpdate,
    onChange,
  );

  const applyHeaderStyle = (): string => {
    if (checkIfAnswer) {
      return `custom-accordion-title bg-green-lime`;
    }
    return `custom-accordion-title bg-grayish-magenta-light`;
  };

  const handleChildClick = (e: React.MouseEvent<HTMLElement>): void => {
    e.stopPropagation();
  };

  const getShowHistoryButton = (): boolean => {
    if (answersList.length === 1) {
      return answersList[0].blockRef === undefined;
    }

    return answersList.length !== 0;
  };

  const posRight: boolean = guidelineLocation === GuidelineLocationTypes.RIGHT;

  const inventoryBtnText: string = checkNotEmpty(parameter.inventoryFunction) ? 'INVENTORY' : `INVENTORY(" ")`;

  const accordionId: string = `${parameter.id}-${tableId}-${tabIndex}`;

  const onClearHistory = (): void => {
    const clearBtn = document.getElementById(accordionId)?.querySelectorAll('.parameters-questions-clear-data')[0];

    // @ts-ignore
    clearBtn.click();

    const paramRef = { parameterId: parameter.id, tableId, index: tabIndex };
    // Delete all the answers of parameter reference
    dispatch(deletePolicyParamRefAnswers(paramRef));
  };

  const showHistoryBtn: boolean = getShowHistoryButton();

  const imageType: boolean = parameter.answerType === AnswerTypes.Image;

  const getDefaultPolicyAnswer = (): string => {
    const size: number = answersList.length;
    if (size > 0) {
      const firstAnswers = answersList[size - 1].answer;
      const valueOfAnswer = getAnswerValue(firstAnswers, parameter.answerType, choices);
      if (imageType) {
        const { title, url } = valueOfAnswer;
        return `${title}-${url}`;
      }

      return valueOfAnswer;
    }
    return '';
  };

  const checkNotesGuidance = (): boolean => {
    if (!posRight) {
      return false;
    }
    let generalGuidance;
    let valuationGuidance;
    if (parameter.guideline) {
      generalGuidance = checkNotEmpty(parameter.guideline.generalGuidance);
      valuationGuidance = checkNotEmpty(parameter.guideline.valuationGuidance);
    } else {
      return false;
    }
    return !checkNotEmpty(parameter.questionNote) && generalGuidance && valuationGuidance;
  };

  // Check if current answer is different then the old answer
  const compareCurrentAnswerWithOld = (): boolean => {
    // Only if old answer exits
    if (answersList.length > 1) {
      const newAnswer: AnswerProps = answersList[0];
      const oldAnswer: AnswerProps = answersList[1];

      // Check if new answer which yet to be saved exists
      if (newAnswer.blockRef !== undefined) {
        // Check if answers are same or not
        switch (newAnswer.answerType) {
          case AnswerTypes.Number:
          case AnswerTypes.NumberPercent:
          case AnswerTypes.NumberUnit:
            // Ignore the type
            return newAnswer.answer.value !== oldAnswer.answer.value;
          default:
            return !isEqual(newAnswer.answer, oldAnswer.answer);
        }
      }
    }
    return false;
  };

  const valuationGuidanceExists: boolean = parameter.guideline
    ? checkNotEmpty(parameter.guideline.valuationGuidance)
    : false;

  return (
    <Accordion
      id={accordionId}
      className="custom-accordion"
    >
      {/* The accordion title is the header of the box.
       * It's background is green when the answer to the parameter is defined and gray when undefined.
       * Alongside the parameter question, it has:
       *  - an 'history' button,
       *  - a 'clear history' button (in policy context only)
       *  - badges to indicate the type of parameter
       */}
      <Accordion.Title
        className={applyHeaderStyle()}
        active={isActive}
        index={0}
        onClick={() => setIsActive(!isActive)}
        data-test="header-title"
      >
        {parameter.question}
        {showHistoryBtn && (
          <span onClick={handleChildClick}>
            <AnswerHistoryModal
              answerType={parameter.answerType as AnswerTypes}
              question={parameter.question}
              questionNote={parameter.questionNote}
              answers={answersList.reverse()}
              choices={choices as ParameterChoiceBase[]}
              unit={parameter.unit}
              hasInventory={parameter.hasInventory}
              inventoryBtnText={inventoryBtnText}
            />
          </span>
        )}
        <Icon
          icon={Icons.ArrowRightBold}
          className="open-icon"
        />
        {parameter.hasInventory && (
          <Badge
            className="m-l-xs"
            badgeColor={BadgeColor.PURPLE}
          >
            {inventoryBtnText}
          </Badge>
        )}
        {checkIfFromPolicy && !alternative && (
          <Badge
            className="m-l-xs"
            badgeColor={BadgeColor.BLUE}
          >
            STRATEGIC
          </Badge>
        )}
        {checkIfFromDeviation(checkIfFromPolicy, answersList) && (
          <Badge
            className="m-l-xs"
            badgeColor={BadgeColor.ORANGE}
          >
            DEVIATION
          </Badge>
        )}
        {alternative && (
          <Badge
            className="m-l-xs"
            badgeColor={BadgeColor.LIGHT_GREY}
          >
            DETAILED
          </Badge>
        )}

        {checkIfPoliciesPage() && showHistoryBtn && (
          <ConfirmPopup
            trigger={
              <span className="trash-box bg-orange-very-light-grayish">
                <Icon
                  icon={Icons.Trash}
                  className="clear-answer-history color-red-soft"
                />
              </span>
            }
            content="Do you really want to clear parameter history?"
            confirmButtonText="CLEAR"
            onConfirm={onClearHistory}
            cancelButtonText="CANCEL"
          />
        )}
      </Accordion.Title>
      <Accordion.Content
        className="custom-accordion-content"
        active={isActive}
      >
        <Grid>
          <Grid.Row>
            <Grid.Column width={posRight ? 12 : 16}>
              <p className="note">{parameter.questionNote}</p>
              <div
                className={`
                  inside-content 
                  ${imageType && 'image-type'}
                  ${checkNotesGuidance() ? 'check-notes-guidance m-t-sm' : ''}
                `}
              >
                {ParameterQuestionContent}
                {checkIfFromPolicy && (
                  <h4 className="default-policy m-t-xs m-b-xs">Policy: {` ${getDefaultPolicyAnswer()}`}</h4>
                )}
              </div>
              {valuationGuidanceExists && compareCurrentAnswerWithOld() ? (
                <div className="valuation-alert">
                  <Icon
                    className="caution-icon m-r-xs"
                    icon={Icons.Caution}
                  />
                  <span>This parameter may impact rental valuation. Be sure to consider the guidelines.</span>
                </div>
              ) : (
                <></>
              )}
            </Grid.Column>
            <GuideLineBoxes
              guideline={parameter.guideline as GuidelineClone | null | undefined}
              posRight={posRight}
              className="m-t-ml"
            />
          </Grid.Row>
        </Grid>
      </Accordion.Content>
    </Accordion>
  );
};

export default ParameterAnswerAccordion;
